import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    DelegationAccreditationSlotStatsFragment,
    DelegationAccreditationsQuery
} from 'common/src/generated/types';
import { getColorPalette } from 'common/src/util/color';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { executeDelegationAccreditationSlotStatsQuery } from '../../../generated/graphqlHooks';
import { useHeavent } from '../../../hooks/useHeavent';
import { getToken } from '../../../util/aws/cognito';
import { DelegationAccreditationSlotRightPanel } from '../../rightPanel/delegationAccreditationSlotRightPanel';
import { DelegationAccreditationCount } from './delegationAccreditationCount';

interface IDelegationAccreditationsAccreditationProps {
    das: DelegationAccreditationsQuery['event']['delegation']['accreditationsSlots'][number];
    display: 'horizontal' | 'vertical';
    isEventAdmin?: boolean;
    isWeezeentSynchronized: boolean;
}

export const DelegationAccreditationsAccreditation = (
    props: IDelegationAccreditationsAccreditationProps
) => {
    const {
        translate,
        params: { eventId, delegationId }
    } = useHeavent();
    const [stats, setStats] = React.useState<DelegationAccreditationSlotStatsFragment>(props.das);
    const palette = React.useMemo(() => {
        const color = props.das.accreditation.color;

        return getColorPalette(color);
    }, [props.das]);
    const colorCss = React.useMemo(
        () => ({
            background: palette['50'],
            border: `1px solid ${palette['500']}`,
            color: palette['700']
        }),
        [palette]
    );
    const name = React.useMemo(() => {
        const accreditationName = props.das.accreditation.name;
        const slotName = isNonEmptyString(props.das.accreditationSlot.name)
            ? ` - ${props.das.accreditationSlot.name}`
            : '';

        return `${accreditationName}${slotName}`;
    }, [props.das]);
    const reloadStats = React.useCallback(async () => {
        const {
            event: { delegation }
        } = await executeDelegationAccreditationSlotStatsQuery(
            { eventId, delegationId, accreditationSlotId: props.das.accreditationSlot.id },
            await getToken()
        );
        setStats(delegation.accreditationSlot);
    }, [props.das, setStats]);
    const [isPanelOpen, setIsPanelOpen] = React.useState(false);
    const max = stats.maxResources ? `/${stats.maxResources}` : '';
    const assigned = `${stats.assignedResources}${max}`;

    return (
        <>
            <Flex
                align={props.display === 'horizontal' ? 'center' : 'start'}
                css={{
                    ...colorCss,
                    borderRadius: '$1',
                    cursor: props.isEventAdmin ? 'pointer' : 'default',
                    minHeight: '32px',
                    px: '$2',
                    py: props.display === 'horizontal' ? '0' : '$2'
                }}
                direction={props.display === 'horizontal' ? 'row' : 'column'}
                gap={props.display === 'horizontal' ? '6' : '2'}
                width={1}
                onClick={() => {
                    if (props.isEventAdmin) {
                        setIsPanelOpen(true);
                    }
                }}
            >
                <Box css={{ flex: '1' }} fontSize="textXs" fontWeight="medium">
                    {name}
                </Box>

                <DelegationAccreditationCount
                    background={palette['100']}
                    count={assigned}
                    display={props.display}
                    icon="badge-check"
                    text={translate('attribu_es_48167')}
                />

                <DelegationAccreditationCount
                    background={palette['100']}
                    count={stats.wishedResources}
                    display={props.display}
                    icon="hand-holding-heart"
                    text={translate('souhait_es_78101')}
                />

                {props.isWeezeentSynchronized && (
                    <DelegationAccreditationCount
                        background={palette['100']}
                        count={stats.scannedResources}
                        display={props.display}
                        icon="scanner-gun"
                        text={translate('scann_es_13169')}
                    />
                )}
            </Flex>

            {props.isEventAdmin && isPanelOpen && (
                <DelegationAccreditationSlotRightPanel
                    accreditationSlotId={props.das.accreditationSlot.id}
                    delegationId={delegationId}
                    eventId={eventId}
                    onClose={() => {
                        setIsPanelOpen(false);

                        return reloadStats();
                    }}
                />
            )}
        </>
    );
};
