import { injectable } from 'inversify';
import { sortBy } from 'lodash-es';
import {
    ALL_ACCREDITATIONSTATE,
    ALL_CHECKINSTATE,
    ALL_EMAILSTATE,
    ALL_VOLUNTEERREGISTRATIONSTATE,
    CustomFieldWithConditionFragment,
    EmailState,
    EventId,
    FilterType,
    SegmentCustomFieldFragment,
    SegmentId,
    VolunteersRegistrationsSortAttributes,
    VolunteersSegmentsQuery
} from '../../generated/types';
import { CountriesService } from '../../services/countriesService';
import { DateTimeService } from '../../services/dateTimeService';
import { TranslationService } from '../../services/translationService';
import { Filter, PossibleColumn, SegmentService } from '../segment';

interface IGetVolunteersFiltersOptions {
    hasAccreditations: boolean;
    hasDelegations: boolean;
    hasWeezeventV2: boolean;
}

export enum VolunteerDefaultColumns {
    AccreditationState = 'accreditation_state',
    Accreditations = 'accreditations',
    AccreditationsSlots = 'accreditations_slots',
    Availability = 'availability',
    Comments = 'comments',
    CompletedForms = 'forms',
    Delegations = 'delegations',
    Email = 'email',
    FormsInsertedAt = 'forms_inserted_at',
    Id = 'id',
    InsertedAt = 'inserted_at',
    Name = 'name',
    NumberOfAccreditations = 'number_of_accreditations',
    NumberOfAssignments = 'number_of_assignments',
    NumberOfAssignmentsDays = 'number_of_assignments_days',
    NumberOfAvailableDays = 'number_of_available_days',
    Positions = 'positions',
    PositionsCategories = 'positions_categories',
    PreassignedPositions = 'preassigned_positions',
    PreassignedPositionsCategories = 'preassigned_positions_categories',
    TeamCode = 'teamCode',
    VolunteerMinutes = 'volunteer_minutes',
    VolunteerRegistrationState = 'volunteer_registration_state',
    WeezeventBarcodeId = 'weezevent_barcode_id',
    WeezeventShortTag = 'weezevent_short_tag',
    WishedAccreditationSlots = 'wished_accreditation_slots',
    WishedPositions = 'wished_positions'
}

export function columnsToIncludes(columns: string[]) {
    return {
        includeAccreditations:
            columns.includes(VolunteerDefaultColumns.Accreditations) ||
            columns.includes(VolunteerDefaultColumns.WishedAccreditationSlots),
        includeAvailability: columns.includes(VolunteerDefaultColumns.Availability),
        includeComments: columns.includes(VolunteerDefaultColumns.Comments),
        includeForms:
            columns.includes(VolunteerDefaultColumns.CompletedForms) ||
            columns.includes(VolunteerDefaultColumns.FormsInsertedAt) ||
            columns.some((c) => c.startsWith('form_inserted_at')) ||
            columns.includes(VolunteerDefaultColumns.Availability),
        includeDelegation: columns.includes(VolunteerDefaultColumns.Delegations),
        includeNumberOfAccreditations: columns.includes(
            VolunteerDefaultColumns.NumberOfAccreditations
        ),
        includeNumberOfAssignments: columns.includes(VolunteerDefaultColumns.NumberOfAssignments),
        includeNumberOfAssignmentsDays: columns.includes(
            VolunteerDefaultColumns.NumberOfAssignmentsDays
        ),
        includeNumberOfAvailableDays: columns.includes(
            VolunteerDefaultColumns.NumberOfAvailableDays
        ),
        includePositions:
            columns.includes(VolunteerDefaultColumns.Positions) ||
            columns.includes(VolunteerDefaultColumns.PositionsCategories),
        includeTeamCode: columns.includes(VolunteerDefaultColumns.TeamCode),
        includeVolunteerMinutes: columns.includes(VolunteerDefaultColumns.VolunteerMinutes),
        includeWishedPositions: columns.includes(VolunteerDefaultColumns.WishedPositions),
        includePreassignedPositions:
            columns.includes(VolunteerDefaultColumns.PreassignedPositionsCategories) ||
            columns.includes(VolunteerDefaultColumns.PreassignedPositions),
        includeWeezevent: columns.includes(VolunteerDefaultColumns.WeezeventBarcodeId)
    };
}

@injectable()
export class VolunteersSegmentsService extends SegmentService {
    constructor(
        countriesService: CountriesService,
        dateTimeService: DateTimeService,
        translationService: TranslationService
    ) {
        super(countriesService, dateTimeService, translationService);
    }

    getVolunteersFilters(
        eventId: EventId,
        currentSegmentId: SegmentId,
        customFields: SegmentCustomFieldFragment[],
        eventInfos: VolunteersSegmentsQuery['event'],
        { hasAccreditations, hasDelegations, hasWeezeventV2 }: IGetVolunteersFiltersOptions
    ): Filter[] {
        const filters: Filter[] = [
            ...this.getPositionsFilters(eventId, eventInfos),
            ...(hasAccreditations ? this.getAccreditationsFilters(eventId, eventInfos) : [])
        ];

        if (hasDelegations) {
            filters.push(
                {
                    color: 'purple',
                    slug: 'delegation',
                    name: this.t('inscrit_via_la_86398'),
                    fieldType: FilterType.Select,
                    needLoading: true,
                    eventId
                },
                {
                    color: 'purple',
                    slug: 'isDelegationLeader',
                    name: this.t('est_responsable_87662'),
                    fieldType: FilterType.Checkbox
                }
            );
        }

        filters.push({
            color: 'purple',
            slug: 'segments',
            name: this.t('fait_partie_de_44650'),
            fieldType: FilterType.Select,
            values: eventInfos.segmentsFolders
                .flatMap((sf) => sf.segments)
                .filter((s) => s.id !== currentSegmentId),
            hideEmpty: true
        });

        filters.push({
            color: 'purple',
            slug: 'positionsSegments',
            name: this.t('affecter_une_10657'),
            fieldType: FilterType.Select,
            hideEmpty: true,
            needLoading: true,
            eventId
        });

        filters.push({
            color: 'purple',
            slug: 'accreditationsSegments',
            name: this.t('affecter_une_91431'),
            fieldType: FilterType.Select,
            hideEmpty: true,
            needLoading: true,
            eventId
        });

        filters.push({
            color: 'purple',
            slug: 'delegationsSegments',
            name: this.t('affecter_une_45240'),
            fieldType: FilterType.Select,
            hideEmpty: true,
            needLoading: true,
            eventId
        });

        filters.push({
            color: 'purple',
            slug: 'forms',
            name: this.t('formulaires_rem_62495'),
            fieldType: FilterType.Select,
            needLoading: true,
            eventId
        });

        filters.push({
            color: 'purple',
            slug: 'formsInsertedAt',
            name: this.t('dates_de_rempli_65075'),
            fieldType: FilterType.Date,
            hideEmpty: true,
            needLoading: true,
            eventId
        });

        filters.push({
            color: 'purple',
            slug: 'insertedAt',
            name: this.t('date_d_inscript_31369'),
            fieldType: FilterType.Date,
            hideEmpty: true
        });

        filters.push(
            {
                color: 'purple',
                slug: 'isTeam',
                name: this.t('est_inscrit_en_86193'),
                fieldType: FilterType.Checkbox,
                hideEmpty: true
            },
            {
                color: 'purple',
                slug: 'teamCode',
                name: this.t('code_quipe_14296'),
                fieldType: FilterType.Text
            },
            {
                color: 'purple',
                slug: 'numberOfTeamMembers',
                name: this.t('nombre_de_membr_82302'),
                fieldType: FilterType.Number
            },
            {
                color: 'purple',
                slug: VolunteerDefaultColumns.WeezeventBarcodeId,
                name: this.t('code_barres_60437'),
                fieldType: FilterType.Text,
                hideEmpty: hasWeezeventV2
            },
            {
                color: 'purple',
                slug: VolunteerDefaultColumns.WeezeventShortTag,
                name: this.t('puce_50504'),
                fieldType: FilterType.Text
            }
        );

        filters.push(
            {
                color: 'success',
                slug: 'isLeader',
                name: this.t('est_responsable_19679'),
                fieldType: FilterType.Checkbox
            },
            {
                color: 'success',
                slug: 'isPositionLeader',
                name: this.t('est_responsable_27270'),
                fieldType: FilterType.Checkbox
            },
            {
                color: 'success',
                slug: 'isPositionCategoryLeader',
                name: this.t('est_responsable_72923'),
                fieldType: FilterType.Checkbox
            }
        );

        filters.push({
            color: 'success',
            slug: 'comments',
            name: this.t('commentaires_96492'),
            fieldType: FilterType.Text
        });

        filters.push({
            color: 'success',
            slug: 'campaign',
            name: this.t('campagne_97871'),
            fieldType: FilterType.Campaign,
            needLoading: true,
            eventId
        });

        filters.push({
            color: 'success',
            slug: 'assignmentEmailSentAt',
            name: this.t('date_d_envoi_de_01614'),
            fieldType: FilterType.Date
        });

        filters.push({
            color: 'success',
            slug: 'assignmentEmailState',
            name: this.t('statut_de_de_l_36346'),
            fieldType: FilterType.Select,
            values: ALL_EMAILSTATE.filter((s) => s !== EmailState.Queued).map((emailState) => ({
                id: emailState,
                name: this.t(emailState)
            })),
            hideEmpty: true
        });

        filters.push({
            color: 'success',
            slug: 'accreditationEmailState',
            name: this.t('statut_de_l_e_m_97776'),
            fieldType: FilterType.Select,
            values: ALL_EMAILSTATE.filter((s) => s !== EmailState.Queued).map((emailState) => ({
                id: emailState,
                name: this.t(emailState)
            })),
            hideEmpty: true
        });

        filters.push({
            color: 'success',
            slug: 'emailBlacklist',
            name: this.t('ne_souhaite_plu_08853'),
            fieldType: FilterType.Checkbox,
            hideEmpty: true
        });

        filters.push({
            color: 'warning',
            slug: 'email',
            name: this.t('Email'),
            fieldType: FilterType.Text,
            hideEmpty: true
        });

        filters.push(...this.customFieldsToFilters(customFields));

        return filters;
    }

    getPositionMembersFilters(
        eventId: EventId,
        customFields: SegmentCustomFieldFragment[],
        eventInfos: VolunteersSegmentsQuery['event']
    ): Filter[] {
        return [
            ...this.getPositionsFilters(eventId, eventInfos),
            ...this.customFieldsToFilters(customFields)
        ];
    }

    getAccreditationMembersFilters(
        eventId: EventId,
        customFields: SegmentCustomFieldFragment[],
        eventInfos: VolunteersSegmentsQuery['event']
    ): Filter[] {
        return [
            ...this.getAccreditationsFilters(eventId, eventInfos),
            ...this.customFieldsToFilters(customFields)
        ];
    }

    getVolunteersPossibleColumns(
        customFields: SegmentCustomFieldFragment[] | CustomFieldWithConditionFragment[],
        forms: VolunteersSegmentsQuery['event']['forms'],
        hasAccreditations: boolean
    ): PossibleColumn[] {
        const otherColumns: PossibleColumn[] = [
            {
                slug: VolunteerDefaultColumns.Availability,
                name: this.t('disponibilit_s_49923')
            },
            {
                slug: VolunteerDefaultColumns.Comments,
                name: this.t('commentaires_96492'),
                isSearchable: true
            },
            {
                slug: VolunteerDefaultColumns.Delegations,
                name: this.t('Delegation'),
                isSearchable: true
            }
        ];

        return [
            ...this.getBaseColumns(),
            ...this.getFormsColumns(forms),
            ...this.getPositionsColumns(),
            ...this.getWeezeventColumns(),
            ...(hasAccreditations ? this.getAccreditationsColumns() : []),
            ...otherColumns,
            ...this.customFieldsToPossibleColumns(customFields)
        ];
    }

    getPositionMembersPossibleColumns(
        customFields: SegmentCustomFieldFragment[] | CustomFieldWithConditionFragment[]
    ): PossibleColumn[] {
        return [
            ...this.getBaseColumns(),
            ...this.getPositionsColumns(),
            ...this.customFieldsToPossibleColumns(customFields)
        ];
    }

    getAccreditationMembersPossibleColumns(
        customFields: SegmentCustomFieldFragment[] | CustomFieldWithConditionFragment[]
    ): PossibleColumn[] {
        return [
            ...this.getBaseColumns(),
            ...this.getAccreditationsColumns(),
            ...this.customFieldsToPossibleColumns(customFields)
        ];
    }

    private getBaseColumns(): PossibleColumn[] {
        return [
            {
                slug: VolunteerDefaultColumns.Email,
                name: this.t('e_mail_du_membr_69509'),
                isSearchable: true
            },
            {
                slug: VolunteerDefaultColumns.Id,
                name: this.t('id_51738'),
                isSearchable: true,
                isSortable: true,
                sortAttribute: VolunteersRegistrationsSortAttributes.Id,
                width: 100
            },
            {
                slug: VolunteerDefaultColumns.Name,
                name: this.t('full_name'),
                isSearchable: true,
                isSortable: true,
                sortAttribute: VolunteersRegistrationsSortAttributes.Name
            },
            {
                slug: VolunteerDefaultColumns.InsertedAt,
                name: this.t('date_d_inscript_31369'),
                isSortable: true,
                sortAttribute: VolunteersRegistrationsSortAttributes.InsertedAt,
                width: 230
            }
        ];
    }

    private getFormsColumns(forms: VolunteersSegmentsQuery['event']['forms']): PossibleColumn[] {
        return [
            {
                slug: VolunteerDefaultColumns.CompletedForms,
                name: this.t('formulaires_rem_62495')
            },
            {
                slug: VolunteerDefaultColumns.FormsInsertedAt,
                name: this.t('dates_de_rempli_65075')
            },
            ...sortBy(forms, (f) => f.name).map((f) => ({
                slug: `form_inserted_at_${f.id}`,
                name: this.t('date_de_remplis_00881', f.name),
                isSortable: true,
                sortAttribute: `form_inserted_at_${f.id}`,
                width: 230
            })),
            {
                slug: VolunteerDefaultColumns.TeamCode,
                name: this.t('code_quipe_14296'),
                isSearchable: true,
                isSortable: true,
                sortAttribute: VolunteersRegistrationsSortAttributes.TeamCode
            }
        ];
    }

    private getPositionsColumns(): PossibleColumn[] {
        return [
            {
                slug: VolunteerDefaultColumns.NumberOfAssignments,
                name: this.t('nombre_d_affect_31455'),
                width: 230
            },
            {
                slug: VolunteerDefaultColumns.NumberOfAssignmentsDays,
                name: this.t('nombre_de_jours_86280'),
                width: 230
            },
            {
                slug: VolunteerDefaultColumns.NumberOfAvailableDays,
                name: this.t('nombre_de_jours_07962'),
                width: 230
            },
            {
                slug: VolunteerDefaultColumns.Positions,
                name: this.t('missions_affect_97811')
            },
            {
                slug: VolunteerDefaultColumns.PositionsCategories,
                name: this.t('cat_gories_de_m_93035')
            },
            {
                slug: VolunteerDefaultColumns.VolunteerMinutes,
                name: this.t('nombre_de_minut_50497'),
                width: 230
            },
            {
                slug: VolunteerDefaultColumns.VolunteerRegistrationState,
                name: this.t('status_d_affect_75769'),
                isSortable: true,
                sortAttribute: VolunteersRegistrationsSortAttributes.State,
                width: 230
            },
            {
                slug: VolunteerDefaultColumns.WishedPositions,
                name: this.t('missions_souhai_23225')
            },
            {
                slug: VolunteerDefaultColumns.PreassignedPositions,
                name: this.t('missions_pr_af_02950')
            },
            {
                slug: VolunteerDefaultColumns.PreassignedPositionsCategories,
                name: this.t('cat_gories_pr_83825')
            }
        ];
    }

    private getAccreditationsColumns(): PossibleColumn[] {
        return [
            {
                slug: VolunteerDefaultColumns.Accreditations,
                name: this.t('accr_ditations_33155')
            },
            {
                slug: VolunteerDefaultColumns.AccreditationState,
                name: this.t('statut_d_accr_d_75527'),
                isSortable: true,
                sortAttribute: VolunteersRegistrationsSortAttributes.AccreditationState,
                width: 240
            },
            {
                slug: VolunteerDefaultColumns.NumberOfAccreditations,
                name: this.t('nombre_d_accr_d_02452'),
                width: 230
            },
            {
                slug: VolunteerDefaultColumns.WishedAccreditationSlots,
                name: this.t('accr_ditations_74375')
            }
        ];
    }

    private getWeezeventColumns(): PossibleColumn[] {
        return [
            {
                slug: VolunteerDefaultColumns.WeezeventBarcodeId,
                name: this.t('code_barres_60437'),
                isSearchable: true
            },
            {
                slug: VolunteerDefaultColumns.WeezeventShortTag,
                name: this.t('puce_50504'),
                isSearchable: true
            }
        ];
    }

    private getPositionsFilters(
        eventId: EventId,
        eventInfos: VolunteersSegmentsQuery['event']
    ): Filter[] {
        return [
            {
                color: 'purple',
                slug: 'volunteerRegistrationState',
                name: this.t('statut_d_affect_57444'),
                fieldType: FilterType.Select,
                values: ALL_VOLUNTEERREGISTRATIONSTATE.map((state) => ({
                    id: state,
                    name: state
                })),
                hideEmpty: true
            },
            {
                color: 'purple',
                slug: 'position',
                name: this.t('missions_affect_97811'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                color: 'purple',
                slug: 'positionSlotId',
                name: this.t('cr_neau_affect_50400_plural'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                color: 'purple',
                slug: 'positionSlot',
                name: this.t('date_d_affectat_98566'),
                fieldType: FilterType.DateTimeRange,
                startAt: eventInfos.startAt,
                endAt: eventInfos.endAt
            },
            {
                color: 'purple',
                slug: 'positionCategory',
                name: this.t('cat_gories_affe_25085'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                color: 'purple',
                slug: 'numberOfAssignments',
                name: this.t('nombre_d_affect_31455'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            {
                color: 'purple',
                slug: 'numberOfAssignmentsDays',
                name: this.t('nombre_de_jours_86280'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            {
                color: 'purple',
                slug: 'numberOfAvailableDays',
                name: this.t('nombre_de_jours_07962'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            {
                color: 'purple',
                slug: 'preassignedPosition',
                name: this.t('missions_pr_af_02950'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                color: 'purple',
                slug: 'preassignedPositionCategory',
                name: this.t('cat_gories_pr_83825'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                color: 'purple',
                slug: 'wishedPositionsCategories',
                name: this.t('cat_gories_souh_80851'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                color: 'purple',
                slug: 'numberOfWishedPositionsCategories',
                name: this.t('nombre_de_cat_g_05961'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            {
                color: 'purple',
                slug: 'wishedPositions',
                name: this.t('missions_souhai_23225'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                color: 'purple',
                slug: 'numberOfWishedPositions',
                name: this.t('nombre_de_missi_52579'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            {
                color: 'purple',
                slug: 'wishedPositionsSlots',
                name: this.t('cr_neaux_souhai_90108'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                color: 'purple',
                slug: 'numberOfWishedPositionsSlots',
                name: this.t('nombre_de_cr_ne_28370'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            {
                color: 'purple',
                slug: 'wishedSlots',
                name: this.t('disponibilit_s_49923'),
                fieldType: FilterType.DateTimeRange,
                startAt: eventInfos.startAt,
                endAt: eventInfos.endAt
            },
            {
                color: 'purple',
                slug: 'wishedSlotsCustom',
                name: this.t('disponibilit_s_26280'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                color: 'purple',
                slug: 'numberOfWishedSlots',
                name: this.t('nombre_de_dispo_26405'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            {
                color: 'purple',
                slug: 'volunteersMinutes',
                name: this.t('nombre_de_minut_50497'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            {
                color: 'purple',
                slug: 'checkInAt',
                name: this.t('point_le_93167'),
                fieldType: FilterType.Date
            },
            {
                color: 'purple',
                slug: 'checkOutAt',
                name: this.t('d_point_le_29289'),
                fieldType: FilterType.Date
            },
            {
                color: 'purple',
                slug: 'checkInState',
                name: this.t('statut_de_point_44328'),
                fieldType: FilterType.Select,
                values: ALL_CHECKINSTATE.map((checkinState) => ({
                    id: checkinState,
                    name: this.t(checkinState)
                })),
                hideEmpty: true
            }
        ];
    }

    private getAccreditationsFilters(
        eventId: EventId,
        eventInfos: VolunteersSegmentsQuery['event']
    ): Filter[] {
        return [
            {
                color: 'purple',
                slug: 'accreditationState',
                name: this.t('statut_d_accr_d_75527'),
                fieldType: FilterType.Select,
                values: ALL_ACCREDITATIONSTATE.map((state) => ({
                    id: state,
                    name: state
                })),
                hideEmpty: true
            },
            {
                color: 'purple',
                slug: 'numberOfAccreditations',
                name: this.t('nombre_d_accr_d_02452'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            {
                color: 'purple',
                slug: 'accreditation',
                name: this.t('accr_ditations_39450'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                color: 'purple',
                slug: 'wishedAccreditationsSlots',
                name: this.t('accr_ditations_74375'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                color: 'purple',
                slug: 'numberOfWishedAccreditationsSlots',
                name: this.t('nombre_d_accr_d_92021'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            {
                color: 'purple',
                slug: 'accreditationSlotId',
                name: this.t('cr_neaux_accr_d_70934'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                eventId
            },
            {
                color: 'purple',
                slug: 'accreditationSlot',
                name: this.t('date_d_accr_dit_26214'),
                fieldType: FilterType.Date,
                startAt: eventInfos.startAt,
                endAt: eventInfos.endAt
            }
        ];
    }
}
