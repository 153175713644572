import { Flex } from 'common/src/designSystem/components/flex';
import { VolunteersRegistrationsSlotInput } from 'common/src/generated/types';
import { fromInterval } from 'common/src/util/luxon';
import { range } from 'lodash-es';
import { DateTime, Interval } from 'luxon';
import * as React from 'react';
import { CalendarInput } from '../../../designSystem/components/date/calendarInput';

interface IFormSlotsCalendarProps {
    currentSlots: VolunteersRegistrationsSlotInput[];
    endAt: DateTime;
    prefix: string;
    startAt: DateTime;

    change(name: string, value: any): void;
}

export const FormSlotsCalendar = (props: IFormSlotsCalendarProps) => {
    const numberOfMonths = React.useMemo(
        () =>
            Math.ceil(
                props.endAt.endOf('month').diff(props.startAt.startOf('month'), 'months').months
            ),
        [props.startAt, props.endAt]
    );
    const onChange = React.useCallback(
        (firstDayOfMonth: DateTime, newDateTimes: DateTime[]) => {
            const newValues = props.currentSlots
                .filter(
                    (slot) =>
                        !(
                            slot.startDate.year === firstDayOfMonth.year &&
                            slot.startDate.month === firstDayOfMonth.month
                        )
                )
                .concat(
                    newDateTimes.map((dt) =>
                        fromInterval(Interval.fromDateTimes(dt, dt.plus({ day: 1 })))
                    )
                );

            props.change(`${props.prefix}slots`, newValues);
        },
        [props.currentSlots, props.prefix, props.change]
    );

    return (
        <Flex gap="6" wrap="wrap">
            {range(0, numberOfMonths).map((i) => {
                const firstDayOfMonth = props.startAt.plus({ month: i }).startOf('month');
                const min = i === 0 ? props.startAt : firstDayOfMonth;
                const max = i === numberOfMonths - 1 ? props.endAt : firstDayOfMonth.endOf('month');
                const values = props.currentSlots.flatMap((slot) => {
                    if (
                        slot.startDate.year === firstDayOfMonth.year &&
                        slot.startDate.month === firstDayOfMonth.month
                    ) {
                        return [slot.startDate.startOf('day')];
                    } else {
                        return [];
                    }
                });

                return (
                    <CalendarInput
                        key={i}
                        firstDayOfMonth={firstDayOfMonth}
                        max={max}
                        min={min}
                        values={values}
                        onChange={(newDateTimes: DateTime[]) => {
                            onChange(firstDayOfMonth, newDateTimes);
                        }}
                    />
                );
            })}
        </Flex>
    );
};
