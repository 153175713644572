import * as yup from 'yup';
import { intercalate, isNonEmptyArray } from './array';

export function normalize(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export enum SlugReplacer {
    Dash,
    Empty,
    Underscore
}

export function slug(str: string, replacer: SlugReplacer = SlugReplacer.Dash): string {
    const newStr = normalize(str).trim().toLowerCase().replace(/['"]+/gi, '');

    if (replacer === SlugReplacer.Dash) {
        return newStr
            .replace(/[^a-z0-9\\-]+/gu, '-')
            .replace(/-+$/, '')
            .replace(/^-+/, '');
    } else if (replacer === SlugReplacer.Underscore) {
        return newStr
            .replace(/[^a-z0-9_]+/gu, '_')
            .replace(/_+$/, '')
            .replace(/^_+/, '');
    } else {
        return newStr.replace(/[^a-z0-9]+/gu, '').trim();
    }
}

export function isNonEmptyString(str: any): str is string {
    return typeof str === 'string' && str.trim().length > 0;
}

export function isEmptyString(str: any): boolean {
    return !isNonEmptyString(str);
}

const capitalizeFirstLetter = (str: string) => {
    if (isNonEmptyString(str)) {
        return str[0].toUpperCase() + str.slice(1);
    } else {
        return '';
    }
};

export function capitalizeName(name?: string | null): string {
    return (name ?? '')
        .trim()
        .toLowerCase()
        .replace(/\s+/g, ' ')
        .split(' ')
        .map((part) => capitalizeFirstLetter(part))
        .join(' ')
        .split('-')
        .map((part) => capitalizeFirstLetter(part.trim()))
        .join('-');
}

export function isInteger(str: any): str is number {
    if (typeof str === 'number' && isFinite(str)) {
        return true;
    } else if (typeof str === 'string' && parseInt(str, 10).toString(10) === str) {
        return true;
    } else {
        return false;
    }
}

export function isValidEmail(email: string): boolean {
    return yup.string().email().required().isValidSync(email);
}

export function isFuzzyMatch(haystack: string, needle: string): boolean {
    return haystack.toUpperCase().includes(needle.toUpperCase());
}

const _split = (str: string, separator: string) =>
    intercalate(
        str.split(new RegExp(separator)).filter((part) => isNonEmptyString(part)),
        separator
    );

export function splitAt(str: string, separators: string[]): string[] {
    if (isNonEmptyArray(separators)) {
        const [firstSeparator, ...remainingSeparators] = separators;
        const result = _split(str, firstSeparator);

        if (isNonEmptyArray(remainingSeparators)) {
            return result.flatMap((part) => splitAt(part, remainingSeparators));
        } else {
            return result;
        }
    } else {
        return [str];
    }
}
