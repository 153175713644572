// /!\ THIS FILE IS AUTOMATICALLY GENERATED, DO NOT EDIT /!\
/* tslint:disable */
import { executeQuery } from '../components/graphql/graphql';
import {
    IUseMutationOptions,
    IUseMutationReturns,
    IUseQueryReturns
} from 'common/src/util/graphql/types';
import { useMutation, useQuery } from 'common/src/util/dependencies/dependencies';

import AccreditAssignWaitingMembers from '../accreditAssign/accreditAssignWaitingMembers.graphql';
import {
    AccreditAssignWaitingMembersQuery,
    AccreditAssignWaitingMembersQueryVariables
} from 'common/src/generated/types';
import AccreditationSlotAssignedMember from '../components/accreditations/sidebars/accreditationSlotAssignedMembers.graphql';
import {
    AccreditationSlotAssignedMemberQuery,
    AccreditationSlotAssignedMemberQueryVariables
} from 'common/src/generated/types';
import VolunteersRegistrationsMassAccredit from '../components/accreditations/volunteersRegistrationsMassAccredit.graphql';
import {
    VolunteersRegistrationsMassAccreditMutation,
    VolunteersRegistrationsMassAccreditMutationVariables
} from 'common/src/generated/types';
import AccreditationsMassEdit from '../components/massEdit/accreditationsMassEdit.graphql';
import {
    AccreditationsMassEditMutation,
    AccreditationsMassEditMutationVariables
} from 'common/src/generated/types';
import AccreditationsMassEditInfos from '../components/massEdit/accreditationsMassEditInfos.graphql';
import {
    AccreditationsMassEditInfosQuery,
    AccreditationsMassEditInfosQueryVariables
} from 'common/src/generated/types';
import DelegationsMassEdit from '../components/massEdit/delegationsMassEdit.graphql';
import {
    DelegationsMassEditMutation,
    DelegationsMassEditMutationVariables
} from 'common/src/generated/types';
import EventDelegationsMassEditInfos from '../components/massEdit/eventDelegationsMassEditInfos.graphql';
import {
    EventDelegationsMassEditInfosQuery,
    EventDelegationsMassEditInfosQueryVariables
} from 'common/src/generated/types';
import OrganizationMassEditInfos from '../components/massEdit/organizationDelegationsMassEditInfos.graphql';
import {
    OrganizationMassEditInfosQuery,
    OrganizationMassEditInfosQueryVariables
} from 'common/src/generated/types';
import PositionsMassEdit from '../components/massEdit/positionsMassEdit.graphql';
import {
    PositionsMassEditMutation,
    PositionsMassEditMutationVariables
} from 'common/src/generated/types';
import PositionsMassEditInfos from '../components/massEdit/positionsMassEditInfos.graphql';
import {
    PositionsMassEditInfosQuery,
    PositionsMassEditInfosQueryVariables
} from 'common/src/generated/types';
import UsersInfosMassEdit from '../components/massEdit/usersInfosMassEdit.graphql';
import {
    UsersInfosMassEditMutation,
    UsersInfosMassEditMutationVariables
} from 'common/src/generated/types';
import UsersInfosMassEditInfos from '../components/massEdit/usersInfosMassEditInfos.graphql';
import {
    UsersInfosMassEditInfosQuery,
    UsersInfosMassEditInfosQueryVariables
} from 'common/src/generated/types';
import DelegationMembers from '../delegations/members/show/delegationMembers.graphql';
import {
    DelegationMembersQuery,
    DelegationMembersQueryVariables
} from 'common/src/generated/types';
import VolunteersRegistrationsAccreditationsValidate from '../delegations/members/show/volunteerRegistrationAccreditationsValidate.graphql';
import {
    VolunteersRegistrationsAccreditationsValidateMutation,
    VolunteersRegistrationsAccreditationsValidateMutationVariables
} from 'common/src/generated/types';
import DelegationAccreditationSlotMember from '../delegations/rightPanel/delegationAccreditationSlotMember.graphql';
import {
    DelegationAccreditationSlotMemberQuery,
    DelegationAccreditationSlotMemberQueryVariables
} from 'common/src/generated/types';
import DelegationAccreditationSlotMembers from '../delegations/rightPanel/delegationAccreditationSlotMembers.graphql';
import {
    DelegationAccreditationSlotMembersQuery,
    DelegationAccreditationSlotMembersQueryVariables
} from 'common/src/generated/types';
import DelegationAccreditationSlotStats from '../delegations/show/accreditations/delegationAccreditationSlotStats.graphql';
import {
    DelegationAccreditationSlotStatsQuery,
    DelegationAccreditationSlotStatsQueryVariables
} from 'common/src/generated/types';
import DelegationAccreditations from '../delegations/show/accreditations/delegationAccreditations.graphql';
import {
    DelegationAccreditationsQuery,
    DelegationAccreditationsQueryVariables
} from 'common/src/generated/types';
import EventDocumentsExport from '../graphql/mutations/eventDocumentsExport.graphql';
import {
    EventDocumentsExportMutation,
    EventDocumentsExportMutationVariables
} from 'common/src/generated/types';
import UserCreate from '../graphql/mutations/userCreate.graphql';
import { UserCreateMutation, UserCreateMutationVariables } from 'common/src/generated/types';
import UserInfoDelegationDelete from '../graphql/mutations/userInfoDelegationDelete.graphql';
import {
    UserInfoDelegationDeleteMutation,
    UserInfoDelegationDeleteMutationVariables
} from 'common/src/generated/types';
import VolunteerRegistrationUpdate from '../graphql/mutations/volunteerRegistrationUpdate.graphql';
import {
    VolunteerRegistrationUpdateMutation,
    VolunteerRegistrationUpdateMutationVariables
} from 'common/src/generated/types';
import ConditionsCustomFields from '../segments/filters/data/conditionsCustomFields.graphql';
import {
    ConditionsCustomFieldsQuery,
    ConditionsCustomFieldsQueryVariables
} from 'common/src/generated/types';
import Tags from '../segments/filters/data/tags.graphql';
import { TagsQuery, TagsQueryVariables } from 'common/src/generated/types';
import UsersSegmentsCampaigns from '../segments/filters/data/usersSegmentsCampaigns.graphql';
import {
    UsersSegmentsCampaignsQuery,
    UsersSegmentsCampaignsQueryVariables
} from 'common/src/generated/types';
import UsersSegmentsDelegations from '../segments/filters/data/usersSegmentsDelegations.graphql';
import {
    UsersSegmentsDelegationsQuery,
    UsersSegmentsDelegationsQueryVariables
} from 'common/src/generated/types';
import UsersSegmentsEvents from '../segments/filters/data/usersSegmentsEvents.graphql';
import {
    UsersSegmentsEventsQuery,
    UsersSegmentsEventsQueryVariables
} from 'common/src/generated/types';
import UsersSegmentsForms from '../segments/filters/data/usersSegmentsForms.graphql';
import {
    UsersSegmentsFormsQuery,
    UsersSegmentsFormsQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsAccreditations from '../segments/filters/data/volunteersSegmentsAccreditations.graphql';
import {
    VolunteersSegmentsAccreditationsQuery,
    VolunteersSegmentsAccreditationsQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsAccreditationsSegments from '../segments/filters/data/volunteersSegmentsAccreditationsSegments.graphql';
import {
    VolunteersSegmentsAccreditationsSegmentsQuery,
    VolunteersSegmentsAccreditationsSegmentsQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsAccreditationsSlots from '../segments/filters/data/volunteersSegmentsAccreditationsSlots.graphql';
import {
    VolunteersSegmentsAccreditationsSlotsQuery,
    VolunteersSegmentsAccreditationsSlotsQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsCampaigns from '../segments/filters/data/volunteersSegmentsCampaigns.graphql';
import {
    VolunteersSegmentsCampaignsQuery,
    VolunteersSegmentsCampaignsQueryVariables
} from 'common/src/generated/types';
import VolunteeersSegmentsCustomSlotsPeriods from '../segments/filters/data/volunteersSegmentsCustomSlotsPeriods.graphql';
import {
    VolunteeersSegmentsCustomSlotsPeriodsQuery,
    VolunteeersSegmentsCustomSlotsPeriodsQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsDelegations from '../segments/filters/data/volunteersSegmentsDelegations.graphql';
import {
    VolunteersSegmentsDelegationsQuery,
    VolunteersSegmentsDelegationsQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsDelegationsSegments from '../segments/filters/data/volunteersSegmentsDelegationsSegments.graphql';
import {
    VolunteersSegmentsDelegationsSegmentsQuery,
    VolunteersSegmentsDelegationsSegmentsQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsForms from '../segments/filters/data/volunteersSegmentsForms.graphql';
import {
    VolunteersSegmentsFormsQuery,
    VolunteersSegmentsFormsQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsPositions from '../segments/filters/data/volunteersSegmentsPositions.graphql';
import {
    VolunteersSegmentsPositionsQuery,
    VolunteersSegmentsPositionsQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsPositionsCategories from '../segments/filters/data/volunteersSegmentsPositionsCategories.graphql';
import {
    VolunteersSegmentsPositionsCategoriesQuery,
    VolunteersSegmentsPositionsCategoriesQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsPositionsSegments from '../segments/filters/data/volunteersSegmentsPositionsSegments.graphql';
import {
    VolunteersSegmentsPositionsSegmentsQuery,
    VolunteersSegmentsPositionsSegmentsQueryVariables
} from 'common/src/generated/types';
import VolunteersSegmentsPositionsSlots from '../segments/filters/data/volunteersSegmentsPositionsSlots.graphql';
import {
    VolunteersSegmentsPositionsSlotsQuery,
    VolunteersSegmentsPositionsSlotsQueryVariables
} from 'common/src/generated/types';
import SegmentCreate from '../segments/graphql/segmentCreate.graphql';
import { SegmentCreateMutation, SegmentCreateMutationVariables } from 'common/src/generated/types';
import SegmentDelete from '../segments/graphql/segmentDelete.graphql';
import { SegmentDeleteMutation, SegmentDeleteMutationVariables } from 'common/src/generated/types';
import SegmentFolderCreate from '../segments/graphql/segmentFolderCreate.graphql';
import {
    SegmentFolderCreateMutation,
    SegmentFolderCreateMutationVariables
} from 'common/src/generated/types';
import SegmentFolderDelete from '../segments/graphql/segmentFolderDelete.graphql';
import {
    SegmentFolderDeleteMutation,
    SegmentFolderDeleteMutationVariables
} from 'common/src/generated/types';
import SegmentFolderMoveDown from '../segments/graphql/segmentFolderMoveDown.graphql';
import {
    SegmentFolderMoveDownMutation,
    SegmentFolderMoveDownMutationVariables
} from 'common/src/generated/types';
import SegmentFolderMoveUp from '../segments/graphql/segmentFolderMoveUp.graphql';
import {
    SegmentFolderMoveUpMutation,
    SegmentFolderMoveUpMutationVariables
} from 'common/src/generated/types';
import SegmentFolderUpdateName from '../segments/graphql/segmentFolderUpdateName.graphql';
import {
    SegmentFolderUpdateNameMutation,
    SegmentFolderUpdateNameMutationVariables
} from 'common/src/generated/types';
import SegmentMoveDown from '../segments/graphql/segmentMoveDown.graphql';
import {
    SegmentMoveDownMutation,
    SegmentMoveDownMutationVariables
} from 'common/src/generated/types';
import SegmentMoveFolder from '../segments/graphql/segmentMoveFolder.graphql';
import {
    SegmentMoveFolderMutation,
    SegmentMoveFolderMutationVariables
} from 'common/src/generated/types';
import SegmentMoveUp from '../segments/graphql/segmentMoveUp.graphql';
import { SegmentMoveUpMutation, SegmentMoveUpMutationVariables } from 'common/src/generated/types';
import SegmentUpdate from '../segments/graphql/segmentUpdate.graphql';
import { SegmentUpdateMutation, SegmentUpdateMutationVariables } from 'common/src/generated/types';
import SegmentUpdateName from '../segments/graphql/segmentUpdateName.graphql';
import {
    SegmentUpdateNameMutation,
    SegmentUpdateNameMutationVariables
} from 'common/src/generated/types';
import SegmentsFoldersReorder from '../segments/list/reorderModal/segmentsFoldersReorder.graphql';
import {
    SegmentsFoldersReorderMutation,
    SegmentsFoldersReorderMutationVariables
} from 'common/src/generated/types';
import VolunteersRegistrationsMassDelete from '../users/delete/volunteersRegistrationsMassDelete.graphql';
import {
    VolunteersRegistrationsMassDeleteMutation,
    VolunteersRegistrationsMassDeleteMutationVariables
} from 'common/src/generated/types';
import Profile from '../users/profile/profile.graphql';
import { ProfileQuery, ProfileQueryVariables } from 'common/src/generated/types';
import UserProfileUpdate from '../users/profile/userProfileUpdate.graphql';
import {
    UserProfileUpdateMutation,
    UserProfileUpdateMutationVariables
} from 'common/src/generated/types';
import EmailUpdate from '../users/update/emailUpdate.graphql';
import { EmailUpdateMutation, EmailUpdateMutationVariables } from 'common/src/generated/types';
import UserInfoUpdate from '../users/update/userInfoUpdate.graphql';
import {
    UserInfoUpdateMutation,
    UserInfoUpdateMutationVariables
} from 'common/src/generated/types';
import UserInfoAccreditationsUpdate from '../users/updateAccreditations/userInfoAccreditationsUpdate.graphql';
import {
    UserInfoAccreditationsUpdateMutation,
    UserInfoAccreditationsUpdateMutationVariables
} from 'common/src/generated/types';
import UserUpdateAccreditationsInfos from '../users/updateAccreditations/userUpdateAccreditationsInfos.graphql';
import {
    UserUpdateAccreditationsInfosQuery,
    UserUpdateAccreditationsInfosQueryVariables
} from 'common/src/generated/types';
import UserInfoPositionsUpdate from '../users/updateMissions/userInfoPositionsUpdate.graphql';
import {
    UserInfoPositionsUpdateMutation,
    UserInfoPositionsUpdateMutationVariables
} from 'common/src/generated/types';
import UserUpdateMissionsInfos from '../users/updateMissions/userUpdateMissionsInfos.graphql';
import {
    UserUpdateMissionsInfosQuery,
    UserUpdateMissionsInfosQueryVariables
} from 'common/src/generated/types';
import UserInfoSlotsUpdate from '../users/updateSlots/userInfoSlotsUpdate.graphql';
import {
    UserInfoSlotsUpdateMutation,
    UserInfoSlotsUpdateMutationVariables
} from 'common/src/generated/types';
import UserUpdateSlotsInfos from '../users/updateSlots/userUpdateSlotsInfos.graphql';
import {
    UserUpdateSlotsInfosQuery,
    UserUpdateSlotsInfosQueryVariables
} from 'common/src/generated/types';
import AwsS3PostCredentials from '../util/awsS3PostCredentials.graphql';
import {
    AwsS3PostCredentialsQuery,
    AwsS3PostCredentialsQueryVariables
} from 'common/src/generated/types';
import AccreditationDuplicate from '../vo/accreditationDuplicate.graphql';
import {
    AccreditationDuplicateMutation,
    AccreditationDuplicateMutationVariables
} from 'common/src/generated/types';
import LoginCodeEmailCreate from '../vo/loginCodeEmailCreate.graphql';
import {
    LoginCodeEmailCreateMutation,
    LoginCodeEmailCreateMutationVariables
} from 'common/src/generated/types';
import PositionDuplicate from '../vo/positionDuplicate.graphql';
import {
    PositionDuplicateMutation,
    PositionDuplicateMutationVariables
} from 'common/src/generated/types';
import CurrentUser from '../vo/user.graphql';
import { CurrentUserQuery, CurrentUserQueryVariables } from 'common/src/generated/types';

import { Fragments } from 'common/src/generated/fragments';
import { IFragment } from 'common/src/generated/fragments';

export interface IQueryFragments {
    query: string;
    fragments: IFragment[];
}

export const Queries = {
    AccreditAssignWaitingMembers,
    AccreditationSlotAssignedMember,
    AccreditationsMassEditInfos,
    EventDelegationsMassEditInfos,
    OrganizationMassEditInfos,
    PositionsMassEditInfos,
    UsersInfosMassEditInfos,
    DelegationMembers,
    DelegationAccreditationSlotMember,
    DelegationAccreditationSlotMembers,
    DelegationAccreditationSlotStats,
    DelegationAccreditations,
    ConditionsCustomFields,
    Tags,
    UsersSegmentsCampaigns,
    UsersSegmentsDelegations,
    UsersSegmentsEvents,
    UsersSegmentsForms,
    VolunteersSegmentsAccreditations,
    VolunteersSegmentsAccreditationsSegments,
    VolunteersSegmentsAccreditationsSlots,
    VolunteersSegmentsCampaigns,
    VolunteeersSegmentsCustomSlotsPeriods,
    VolunteersSegmentsDelegations,
    VolunteersSegmentsDelegationsSegments,
    VolunteersSegmentsForms,
    VolunteersSegmentsPositions,
    VolunteersSegmentsPositionsCategories,
    VolunteersSegmentsPositionsSegments,
    VolunteersSegmentsPositionsSlots,
    Profile,
    UserUpdateAccreditationsInfos,
    UserUpdateMissionsInfos,
    UserUpdateSlotsInfos,
    AwsS3PostCredentials,
    CurrentUser
};

export const Mutations = {
    VolunteersRegistrationsMassAccredit,
    AccreditationsMassEdit,
    DelegationsMassEdit,
    PositionsMassEdit,
    UsersInfosMassEdit,
    VolunteersRegistrationsAccreditationsValidate,
    EventDocumentsExport,
    UserCreate,
    UserInfoDelegationDelete,
    VolunteerRegistrationUpdate,
    SegmentCreate,
    SegmentDelete,
    SegmentFolderCreate,
    SegmentFolderDelete,
    SegmentFolderMoveDown,
    SegmentFolderMoveUp,
    SegmentFolderUpdateName,
    SegmentMoveDown,
    SegmentMoveFolder,
    SegmentMoveUp,
    SegmentUpdate,
    SegmentUpdateName,
    SegmentsFoldersReorder,
    VolunteersRegistrationsMassDelete,
    UserProfileUpdate,
    EmailUpdate,
    UserInfoUpdate,
    UserInfoAccreditationsUpdate,
    UserInfoPositionsUpdate,
    UserInfoSlotsUpdate,
    AccreditationDuplicate,
    LoginCodeEmailCreate,
    PositionDuplicate
};

export function useAccreditAssignWaitingMembersQuery(
    args: AccreditAssignWaitingMembersQueryVariables
): IUseQueryReturns<AccreditAssignWaitingMembersQuery> {
    return useQuery(Queries.AccreditAssignWaitingMembers, args, [
        Fragments.VolunteerRegistrationWaitingAssignmentFragment
    ]);
}

export function executeAccreditAssignWaitingMembersQuery(
    args: AccreditAssignWaitingMembersQueryVariables,
    token: string | null | undefined
): Promise<AccreditAssignWaitingMembersQuery> {
    return executeQuery<AccreditAssignWaitingMembersQuery>(Queries.AccreditAssignWaitingMembers, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.VolunteerRegistrationWaitingAssignmentFragment]
    });
}

export function useAccreditationSlotAssignedMemberQuery(
    args: AccreditationSlotAssignedMemberQueryVariables
): IUseQueryReturns<AccreditationSlotAssignedMemberQuery> {
    return useQuery(Queries.AccreditationSlotAssignedMember, args, []);
}

export function executeAccreditationSlotAssignedMemberQuery(
    args: AccreditationSlotAssignedMemberQueryVariables,
    token: string | null | undefined
): Promise<AccreditationSlotAssignedMemberQuery> {
    return executeQuery<AccreditationSlotAssignedMemberQuery>(
        Queries.AccreditationSlotAssignedMember,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useVolunteersRegistrationsMassAccreditMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsMassAccreditMutation,
    VolunteersRegistrationsMassAccreditMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsMassAccredit, args);
}

export function useAccreditationsMassEditMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<AccreditationsMassEditMutation, AccreditationsMassEditMutationVariables> {
    return useMutation(Mutations.AccreditationsMassEdit, args);
}

export function useAccreditationsMassEditInfosQuery(
    args: AccreditationsMassEditInfosQueryVariables
): IUseQueryReturns<AccreditationsMassEditInfosQuery> {
    return useQuery(Queries.AccreditationsMassEditInfos, args, [
        Fragments.MassEditCustomFieldFragment
    ]);
}

export function executeAccreditationsMassEditInfosQuery(
    args: AccreditationsMassEditInfosQueryVariables,
    token: string | null | undefined
): Promise<AccreditationsMassEditInfosQuery> {
    return executeQuery<AccreditationsMassEditInfosQuery>(Queries.AccreditationsMassEditInfos, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.MassEditCustomFieldFragment]
    });
}

export function useDelegationsMassEditMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<DelegationsMassEditMutation, DelegationsMassEditMutationVariables> {
    return useMutation(Mutations.DelegationsMassEdit, args);
}

export function useEventDelegationsMassEditInfosQuery(
    args: EventDelegationsMassEditInfosQueryVariables
): IUseQueryReturns<EventDelegationsMassEditInfosQuery> {
    return useQuery(Queries.EventDelegationsMassEditInfos, args, [
        Fragments.MassEditCustomFieldFragment
    ]);
}

export function executeEventDelegationsMassEditInfosQuery(
    args: EventDelegationsMassEditInfosQueryVariables,
    token: string | null | undefined
): Promise<EventDelegationsMassEditInfosQuery> {
    return executeQuery<EventDelegationsMassEditInfosQuery>(Queries.EventDelegationsMassEditInfos, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.MassEditCustomFieldFragment]
    });
}

export function useOrganizationMassEditInfosQuery(
    args: OrganizationMassEditInfosQueryVariables
): IUseQueryReturns<OrganizationMassEditInfosQuery> {
    return useQuery(Queries.OrganizationMassEditInfos, args, [
        Fragments.MassEditCustomFieldFragment
    ]);
}

export function executeOrganizationMassEditInfosQuery(
    args: OrganizationMassEditInfosQueryVariables,
    token: string | null | undefined
): Promise<OrganizationMassEditInfosQuery> {
    return executeQuery<OrganizationMassEditInfosQuery>(Queries.OrganizationMassEditInfos, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.MassEditCustomFieldFragment]
    });
}

export function usePositionsMassEditMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<PositionsMassEditMutation, PositionsMassEditMutationVariables> {
    return useMutation(Mutations.PositionsMassEdit, args);
}

export function usePositionsMassEditInfosQuery(
    args: PositionsMassEditInfosQueryVariables
): IUseQueryReturns<PositionsMassEditInfosQuery> {
    return useQuery(Queries.PositionsMassEditInfos, args, [Fragments.MassEditCustomFieldFragment]);
}

export function executePositionsMassEditInfosQuery(
    args: PositionsMassEditInfosQueryVariables,
    token: string | null | undefined
): Promise<PositionsMassEditInfosQuery> {
    return executeQuery<PositionsMassEditInfosQuery>(Queries.PositionsMassEditInfos, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.MassEditCustomFieldFragment]
    });
}

export function useUsersInfosMassEditMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UsersInfosMassEditMutation, UsersInfosMassEditMutationVariables> {
    return useMutation(Mutations.UsersInfosMassEdit, args);
}

export function useUsersInfosMassEditInfosQuery(
    args: UsersInfosMassEditInfosQueryVariables
): IUseQueryReturns<UsersInfosMassEditInfosQuery> {
    return useQuery(Queries.UsersInfosMassEditInfos, args, [Fragments.MassEditCustomFieldFragment]);
}

export function executeUsersInfosMassEditInfosQuery(
    args: UsersInfosMassEditInfosQueryVariables,
    token: string | null | undefined
): Promise<UsersInfosMassEditInfosQuery> {
    return executeQuery<UsersInfosMassEditInfosQuery>(Queries.UsersInfosMassEditInfos, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.MassEditCustomFieldFragment]
    });
}

export function useDelegationMembersQuery(
    args: DelegationMembersQueryVariables
): IUseQueryReturns<DelegationMembersQuery> {
    return useQuery(Queries.DelegationMembers, args, [
        Fragments.MemberRegistrationUserInfoFragment,
        Fragments.FormsUsersInfosWishedFragment,
        Fragments.AccreditationsSlotFragment
    ]);
}

export function executeDelegationMembersQuery(
    args: DelegationMembersQueryVariables,
    token: string | null | undefined
): Promise<DelegationMembersQuery> {
    return executeQuery<DelegationMembersQuery>(Queries.DelegationMembers, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.MemberRegistrationUserInfoFragment,
            Fragments.FormsUsersInfosWishedFragment,
            Fragments.AccreditationsSlotFragment
        ]
    });
}

export function useVolunteersRegistrationsAccreditationsValidateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsAccreditationsValidateMutation,
    VolunteersRegistrationsAccreditationsValidateMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsAccreditationsValidate, args);
}

export function useDelegationAccreditationSlotMemberQuery(
    args: DelegationAccreditationSlotMemberQueryVariables
): IUseQueryReturns<DelegationAccreditationSlotMemberQuery> {
    return useQuery(Queries.DelegationAccreditationSlotMember, args, [
        Fragments.DelegationAccreditationSlotStatsFragment,
        Fragments.VolunteerRegistrationDelegationAccreditationSlotFragment
    ]);
}

export function executeDelegationAccreditationSlotMemberQuery(
    args: DelegationAccreditationSlotMemberQueryVariables,
    token: string | null | undefined
): Promise<DelegationAccreditationSlotMemberQuery> {
    return executeQuery<DelegationAccreditationSlotMemberQuery>(
        Queries.DelegationAccreditationSlotMember,
        {
            variables: args,
            token: token || undefined,
            fragments: [
                Fragments.DelegationAccreditationSlotStatsFragment,
                Fragments.VolunteerRegistrationDelegationAccreditationSlotFragment
            ]
        }
    );
}

export function useDelegationAccreditationSlotMembersQuery(
    args: DelegationAccreditationSlotMembersQueryVariables
): IUseQueryReturns<DelegationAccreditationSlotMembersQuery> {
    return useQuery(Queries.DelegationAccreditationSlotMembers, args, [
        Fragments.DelegationAccreditationSlotStatsFragment,
        Fragments.VolunteerRegistrationDelegationAccreditationSlotFragment
    ]);
}

export function executeDelegationAccreditationSlotMembersQuery(
    args: DelegationAccreditationSlotMembersQueryVariables,
    token: string | null | undefined
): Promise<DelegationAccreditationSlotMembersQuery> {
    return executeQuery<DelegationAccreditationSlotMembersQuery>(
        Queries.DelegationAccreditationSlotMembers,
        {
            variables: args,
            token: token || undefined,
            fragments: [
                Fragments.DelegationAccreditationSlotStatsFragment,
                Fragments.VolunteerRegistrationDelegationAccreditationSlotFragment
            ]
        }
    );
}

export function useDelegationAccreditationSlotStatsQuery(
    args: DelegationAccreditationSlotStatsQueryVariables
): IUseQueryReturns<DelegationAccreditationSlotStatsQuery> {
    return useQuery(Queries.DelegationAccreditationSlotStats, args, [
        Fragments.DelegationAccreditationSlotStatsFragment
    ]);
}

export function executeDelegationAccreditationSlotStatsQuery(
    args: DelegationAccreditationSlotStatsQueryVariables,
    token: string | null | undefined
): Promise<DelegationAccreditationSlotStatsQuery> {
    return executeQuery<DelegationAccreditationSlotStatsQuery>(
        Queries.DelegationAccreditationSlotStats,
        {
            variables: args,
            token: token || undefined,
            fragments: [Fragments.DelegationAccreditationSlotStatsFragment]
        }
    );
}

export function useDelegationAccreditationsQuery(
    args: DelegationAccreditationsQueryVariables
): IUseQueryReturns<DelegationAccreditationsQuery> {
    return useQuery(Queries.DelegationAccreditations, args, [
        Fragments.DelegationAccreditationSlotStatsFragment
    ]);
}

export function executeDelegationAccreditationsQuery(
    args: DelegationAccreditationsQueryVariables,
    token: string | null | undefined
): Promise<DelegationAccreditationsQuery> {
    return executeQuery<DelegationAccreditationsQuery>(Queries.DelegationAccreditations, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.DelegationAccreditationSlotStatsFragment]
    });
}

export function useEventDocumentsExportMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EventDocumentsExportMutation, EventDocumentsExportMutationVariables> {
    return useMutation(Mutations.EventDocumentsExport, args);
}

export function useUserCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UserCreateMutation, UserCreateMutationVariables> {
    return useMutation(Mutations.UserCreate, args);
}

export function useUserInfoDelegationDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    UserInfoDelegationDeleteMutation,
    UserInfoDelegationDeleteMutationVariables
> {
    return useMutation(Mutations.UserInfoDelegationDelete, args);
}

export function useVolunteerRegistrationUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteerRegistrationUpdateMutation,
    VolunteerRegistrationUpdateMutationVariables
> {
    return useMutation(Mutations.VolunteerRegistrationUpdate, args);
}

export function useConditionsCustomFieldsQuery(
    args: ConditionsCustomFieldsQueryVariables
): IUseQueryReturns<ConditionsCustomFieldsQuery> {
    return useQuery(Queries.ConditionsCustomFields, args, []);
}

export function executeConditionsCustomFieldsQuery(
    args: ConditionsCustomFieldsQueryVariables,
    token: string | null | undefined
): Promise<ConditionsCustomFieldsQuery> {
    return executeQuery<ConditionsCustomFieldsQuery>(Queries.ConditionsCustomFields, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useTagsQuery(args: TagsQueryVariables): IUseQueryReturns<TagsQuery> {
    return useQuery(Queries.Tags, args, []);
}

export function executeTagsQuery(
    args: TagsQueryVariables,
    token: string | null | undefined
): Promise<TagsQuery> {
    return executeQuery<TagsQuery>(Queries.Tags, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUsersSegmentsCampaignsQuery(
    args: UsersSegmentsCampaignsQueryVariables
): IUseQueryReturns<UsersSegmentsCampaignsQuery> {
    return useQuery(Queries.UsersSegmentsCampaigns, args, [Fragments.SegmentCampaignFragment]);
}

export function executeUsersSegmentsCampaignsQuery(
    args: UsersSegmentsCampaignsQueryVariables,
    token: string | null | undefined
): Promise<UsersSegmentsCampaignsQuery> {
    return executeQuery<UsersSegmentsCampaignsQuery>(Queries.UsersSegmentsCampaigns, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.SegmentCampaignFragment]
    });
}

export function useUsersSegmentsDelegationsQuery(
    args: UsersSegmentsDelegationsQueryVariables
): IUseQueryReturns<UsersSegmentsDelegationsQuery> {
    return useQuery(Queries.UsersSegmentsDelegations, args, []);
}

export function executeUsersSegmentsDelegationsQuery(
    args: UsersSegmentsDelegationsQueryVariables,
    token: string | null | undefined
): Promise<UsersSegmentsDelegationsQuery> {
    return executeQuery<UsersSegmentsDelegationsQuery>(Queries.UsersSegmentsDelegations, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUsersSegmentsEventsQuery(
    args: UsersSegmentsEventsQueryVariables
): IUseQueryReturns<UsersSegmentsEventsQuery> {
    return useQuery(Queries.UsersSegmentsEvents, args, [Fragments.SegmentEventFragment]);
}

export function executeUsersSegmentsEventsQuery(
    args: UsersSegmentsEventsQueryVariables,
    token: string | null | undefined
): Promise<UsersSegmentsEventsQuery> {
    return executeQuery<UsersSegmentsEventsQuery>(Queries.UsersSegmentsEvents, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.SegmentEventFragment]
    });
}

export function useUsersSegmentsFormsQuery(
    args: UsersSegmentsFormsQueryVariables
): IUseQueryReturns<UsersSegmentsFormsQuery> {
    return useQuery(Queries.UsersSegmentsForms, args, [Fragments.SegmentFormFragment]);
}

export function executeUsersSegmentsFormsQuery(
    args: UsersSegmentsFormsQueryVariables,
    token: string | null | undefined
): Promise<UsersSegmentsFormsQuery> {
    return executeQuery<UsersSegmentsFormsQuery>(Queries.UsersSegmentsForms, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.SegmentFormFragment]
    });
}

export function useVolunteersSegmentsAccreditationsQuery(
    args: VolunteersSegmentsAccreditationsQueryVariables
): IUseQueryReturns<VolunteersSegmentsAccreditationsQuery> {
    return useQuery(Queries.VolunteersSegmentsAccreditations, args, []);
}

export function executeVolunteersSegmentsAccreditationsQuery(
    args: VolunteersSegmentsAccreditationsQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsAccreditationsQuery> {
    return executeQuery<VolunteersSegmentsAccreditationsQuery>(
        Queries.VolunteersSegmentsAccreditations,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useVolunteersSegmentsAccreditationsSegmentsQuery(
    args: VolunteersSegmentsAccreditationsSegmentsQueryVariables
): IUseQueryReturns<VolunteersSegmentsAccreditationsSegmentsQuery> {
    return useQuery(Queries.VolunteersSegmentsAccreditationsSegments, args, []);
}

export function executeVolunteersSegmentsAccreditationsSegmentsQuery(
    args: VolunteersSegmentsAccreditationsSegmentsQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsAccreditationsSegmentsQuery> {
    return executeQuery<VolunteersSegmentsAccreditationsSegmentsQuery>(
        Queries.VolunteersSegmentsAccreditationsSegments,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useVolunteersSegmentsAccreditationsSlotsQuery(
    args: VolunteersSegmentsAccreditationsSlotsQueryVariables
): IUseQueryReturns<VolunteersSegmentsAccreditationsSlotsQuery> {
    return useQuery(Queries.VolunteersSegmentsAccreditationsSlots, args, []);
}

export function executeVolunteersSegmentsAccreditationsSlotsQuery(
    args: VolunteersSegmentsAccreditationsSlotsQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsAccreditationsSlotsQuery> {
    return executeQuery<VolunteersSegmentsAccreditationsSlotsQuery>(
        Queries.VolunteersSegmentsAccreditationsSlots,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useVolunteersSegmentsCampaignsQuery(
    args: VolunteersSegmentsCampaignsQueryVariables
): IUseQueryReturns<VolunteersSegmentsCampaignsQuery> {
    return useQuery(Queries.VolunteersSegmentsCampaigns, args, [Fragments.SegmentCampaignFragment]);
}

export function executeVolunteersSegmentsCampaignsQuery(
    args: VolunteersSegmentsCampaignsQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsCampaignsQuery> {
    return executeQuery<VolunteersSegmentsCampaignsQuery>(Queries.VolunteersSegmentsCampaigns, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.SegmentCampaignFragment]
    });
}

export function useVolunteeersSegmentsCustomSlotsPeriodsQuery(
    args: VolunteeersSegmentsCustomSlotsPeriodsQueryVariables
): IUseQueryReturns<VolunteeersSegmentsCustomSlotsPeriodsQuery> {
    return useQuery(Queries.VolunteeersSegmentsCustomSlotsPeriods, args, []);
}

export function executeVolunteeersSegmentsCustomSlotsPeriodsQuery(
    args: VolunteeersSegmentsCustomSlotsPeriodsQueryVariables,
    token: string | null | undefined
): Promise<VolunteeersSegmentsCustomSlotsPeriodsQuery> {
    return executeQuery<VolunteeersSegmentsCustomSlotsPeriodsQuery>(
        Queries.VolunteeersSegmentsCustomSlotsPeriods,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useVolunteersSegmentsDelegationsQuery(
    args: VolunteersSegmentsDelegationsQueryVariables
): IUseQueryReturns<VolunteersSegmentsDelegationsQuery> {
    return useQuery(Queries.VolunteersSegmentsDelegations, args, []);
}

export function executeVolunteersSegmentsDelegationsQuery(
    args: VolunteersSegmentsDelegationsQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsDelegationsQuery> {
    return executeQuery<VolunteersSegmentsDelegationsQuery>(Queries.VolunteersSegmentsDelegations, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useVolunteersSegmentsDelegationsSegmentsQuery(
    args: VolunteersSegmentsDelegationsSegmentsQueryVariables
): IUseQueryReturns<VolunteersSegmentsDelegationsSegmentsQuery> {
    return useQuery(Queries.VolunteersSegmentsDelegationsSegments, args, []);
}

export function executeVolunteersSegmentsDelegationsSegmentsQuery(
    args: VolunteersSegmentsDelegationsSegmentsQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsDelegationsSegmentsQuery> {
    return executeQuery<VolunteersSegmentsDelegationsSegmentsQuery>(
        Queries.VolunteersSegmentsDelegationsSegments,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useVolunteersSegmentsFormsQuery(
    args: VolunteersSegmentsFormsQueryVariables
): IUseQueryReturns<VolunteersSegmentsFormsQuery> {
    return useQuery(Queries.VolunteersSegmentsForms, args, [Fragments.SegmentFormFragment]);
}

export function executeVolunteersSegmentsFormsQuery(
    args: VolunteersSegmentsFormsQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsFormsQuery> {
    return executeQuery<VolunteersSegmentsFormsQuery>(Queries.VolunteersSegmentsForms, {
        variables: args,
        token: token || undefined,
        fragments: [Fragments.SegmentFormFragment]
    });
}

export function useVolunteersSegmentsPositionsQuery(
    args: VolunteersSegmentsPositionsQueryVariables
): IUseQueryReturns<VolunteersSegmentsPositionsQuery> {
    return useQuery(Queries.VolunteersSegmentsPositions, args, []);
}

export function executeVolunteersSegmentsPositionsQuery(
    args: VolunteersSegmentsPositionsQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsPositionsQuery> {
    return executeQuery<VolunteersSegmentsPositionsQuery>(Queries.VolunteersSegmentsPositions, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useVolunteersSegmentsPositionsCategoriesQuery(
    args: VolunteersSegmentsPositionsCategoriesQueryVariables
): IUseQueryReturns<VolunteersSegmentsPositionsCategoriesQuery> {
    return useQuery(Queries.VolunteersSegmentsPositionsCategories, args, []);
}

export function executeVolunteersSegmentsPositionsCategoriesQuery(
    args: VolunteersSegmentsPositionsCategoriesQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsPositionsCategoriesQuery> {
    return executeQuery<VolunteersSegmentsPositionsCategoriesQuery>(
        Queries.VolunteersSegmentsPositionsCategories,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useVolunteersSegmentsPositionsSegmentsQuery(
    args: VolunteersSegmentsPositionsSegmentsQueryVariables
): IUseQueryReturns<VolunteersSegmentsPositionsSegmentsQuery> {
    return useQuery(Queries.VolunteersSegmentsPositionsSegments, args, []);
}

export function executeVolunteersSegmentsPositionsSegmentsQuery(
    args: VolunteersSegmentsPositionsSegmentsQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsPositionsSegmentsQuery> {
    return executeQuery<VolunteersSegmentsPositionsSegmentsQuery>(
        Queries.VolunteersSegmentsPositionsSegments,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useVolunteersSegmentsPositionsSlotsQuery(
    args: VolunteersSegmentsPositionsSlotsQueryVariables
): IUseQueryReturns<VolunteersSegmentsPositionsSlotsQuery> {
    return useQuery(Queries.VolunteersSegmentsPositionsSlots, args, []);
}

export function executeVolunteersSegmentsPositionsSlotsQuery(
    args: VolunteersSegmentsPositionsSlotsQueryVariables,
    token: string | null | undefined
): Promise<VolunteersSegmentsPositionsSlotsQuery> {
    return executeQuery<VolunteersSegmentsPositionsSlotsQuery>(
        Queries.VolunteersSegmentsPositionsSlots,
        {
            variables: args,
            token: token || undefined,
            fragments: []
        }
    );
}

export function useSegmentCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentCreateMutation, SegmentCreateMutationVariables> {
    return useMutation(Mutations.SegmentCreate, args);
}

export function useSegmentDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentDeleteMutation, SegmentDeleteMutationVariables> {
    return useMutation(Mutations.SegmentDelete, args);
}

export function useSegmentFolderCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentFolderCreateMutation, SegmentFolderCreateMutationVariables> {
    return useMutation(Mutations.SegmentFolderCreate, args);
}

export function useSegmentFolderDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentFolderDeleteMutation, SegmentFolderDeleteMutationVariables> {
    return useMutation(Mutations.SegmentFolderDelete, args);
}

export function useSegmentFolderMoveDownMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentFolderMoveDownMutation, SegmentFolderMoveDownMutationVariables> {
    return useMutation(Mutations.SegmentFolderMoveDown, args);
}

export function useSegmentFolderMoveUpMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentFolderMoveUpMutation, SegmentFolderMoveUpMutationVariables> {
    return useMutation(Mutations.SegmentFolderMoveUp, args);
}

export function useSegmentFolderUpdateNameMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentFolderUpdateNameMutation, SegmentFolderUpdateNameMutationVariables> {
    return useMutation(Mutations.SegmentFolderUpdateName, args);
}

export function useSegmentMoveDownMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentMoveDownMutation, SegmentMoveDownMutationVariables> {
    return useMutation(Mutations.SegmentMoveDown, args);
}

export function useSegmentMoveFolderMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentMoveFolderMutation, SegmentMoveFolderMutationVariables> {
    return useMutation(Mutations.SegmentMoveFolder, args);
}

export function useSegmentMoveUpMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentMoveUpMutation, SegmentMoveUpMutationVariables> {
    return useMutation(Mutations.SegmentMoveUp, args);
}

export function useSegmentUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentUpdateMutation, SegmentUpdateMutationVariables> {
    return useMutation(Mutations.SegmentUpdate, args);
}

export function useSegmentUpdateNameMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentUpdateNameMutation, SegmentUpdateNameMutationVariables> {
    return useMutation(Mutations.SegmentUpdateName, args);
}

export function useSegmentsFoldersReorderMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<SegmentsFoldersReorderMutation, SegmentsFoldersReorderMutationVariables> {
    return useMutation(Mutations.SegmentsFoldersReorder, args);
}

export function useVolunteersRegistrationsMassDeleteMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    VolunteersRegistrationsMassDeleteMutation,
    VolunteersRegistrationsMassDeleteMutationVariables
> {
    return useMutation(Mutations.VolunteersRegistrationsMassDelete, args);
}

export function useProfileQuery(args: ProfileQueryVariables): IUseQueryReturns<ProfileQuery> {
    return useQuery(Queries.Profile, args, []);
}

export function executeProfileQuery(
    args: ProfileQueryVariables,
    token: string | null | undefined
): Promise<ProfileQuery> {
    return executeQuery<ProfileQuery>(Queries.Profile, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useUserProfileUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UserProfileUpdateMutation, UserProfileUpdateMutationVariables> {
    return useMutation(Mutations.UserProfileUpdate, args);
}

export function useEmailUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<EmailUpdateMutation, EmailUpdateMutationVariables> {
    return useMutation(Mutations.EmailUpdate, args);
}

export function useUserInfoUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UserInfoUpdateMutation, UserInfoUpdateMutationVariables> {
    return useMutation(Mutations.UserInfoUpdate, args);
}

export function useUserInfoAccreditationsUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<
    UserInfoAccreditationsUpdateMutation,
    UserInfoAccreditationsUpdateMutationVariables
> {
    return useMutation(Mutations.UserInfoAccreditationsUpdate, args);
}

export function useUserUpdateAccreditationsInfosQuery(
    args: UserUpdateAccreditationsInfosQueryVariables
): IUseQueryReturns<UserUpdateAccreditationsInfosQuery> {
    return useQuery(Queries.UserUpdateAccreditationsInfos, args, [
        Fragments.MemberRegistrationUserInfoFragment,
        Fragments.FormsUsersInfosWishedFragment,
        Fragments.DelegationAccreditationSlotFragment,
        Fragments.FormMemberRegistrationFragment,
        Fragments.FormRegisterAccreditationCategoryFragment
    ]);
}

export function executeUserUpdateAccreditationsInfosQuery(
    args: UserUpdateAccreditationsInfosQueryVariables,
    token: string | null | undefined
): Promise<UserUpdateAccreditationsInfosQuery> {
    return executeQuery<UserUpdateAccreditationsInfosQuery>(Queries.UserUpdateAccreditationsInfos, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.MemberRegistrationUserInfoFragment,
            Fragments.FormsUsersInfosWishedFragment,
            Fragments.DelegationAccreditationSlotFragment,
            Fragments.FormMemberRegistrationFragment,
            Fragments.FormRegisterAccreditationCategoryFragment
        ]
    });
}

export function useUserInfoPositionsUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UserInfoPositionsUpdateMutation, UserInfoPositionsUpdateMutationVariables> {
    return useMutation(Mutations.UserInfoPositionsUpdate, args);
}

export function useUserUpdateMissionsInfosQuery(
    args: UserUpdateMissionsInfosQueryVariables
): IUseQueryReturns<UserUpdateMissionsInfosQuery> {
    return useQuery(Queries.UserUpdateMissionsInfos, args, [
        Fragments.MemberRegistrationUserInfoFragment,
        Fragments.FormsUsersInfosWishedFragment,
        Fragments.FormMemberRegistrationFragment,
        Fragments.FormRegisterCategoryFragment
    ]);
}

export function executeUserUpdateMissionsInfosQuery(
    args: UserUpdateMissionsInfosQueryVariables,
    token: string | null | undefined
): Promise<UserUpdateMissionsInfosQuery> {
    return executeQuery<UserUpdateMissionsInfosQuery>(Queries.UserUpdateMissionsInfos, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.MemberRegistrationUserInfoFragment,
            Fragments.FormsUsersInfosWishedFragment,
            Fragments.FormMemberRegistrationFragment,
            Fragments.FormRegisterCategoryFragment
        ]
    });
}

export function useUserInfoSlotsUpdateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<UserInfoSlotsUpdateMutation, UserInfoSlotsUpdateMutationVariables> {
    return useMutation(Mutations.UserInfoSlotsUpdate, args);
}

export function useUserUpdateSlotsInfosQuery(
    args: UserUpdateSlotsInfosQueryVariables
): IUseQueryReturns<UserUpdateSlotsInfosQuery> {
    return useQuery(Queries.UserUpdateSlotsInfos, args, [
        Fragments.MemberRegistrationUserInfoFragment,
        Fragments.FormsUsersInfosWishedFragment,
        Fragments.FormMemberRegistrationFragment
    ]);
}

export function executeUserUpdateSlotsInfosQuery(
    args: UserUpdateSlotsInfosQueryVariables,
    token: string | null | undefined
): Promise<UserUpdateSlotsInfosQuery> {
    return executeQuery<UserUpdateSlotsInfosQuery>(Queries.UserUpdateSlotsInfos, {
        variables: args,
        token: token || undefined,
        fragments: [
            Fragments.MemberRegistrationUserInfoFragment,
            Fragments.FormsUsersInfosWishedFragment,
            Fragments.FormMemberRegistrationFragment
        ]
    });
}

export function useAwsS3PostCredentialsQuery(
    args: AwsS3PostCredentialsQueryVariables
): IUseQueryReturns<AwsS3PostCredentialsQuery> {
    return useQuery(Queries.AwsS3PostCredentials, args, []);
}

export function executeAwsS3PostCredentialsQuery(
    args: AwsS3PostCredentialsQueryVariables,
    token: string | null | undefined
): Promise<AwsS3PostCredentialsQuery> {
    return executeQuery<AwsS3PostCredentialsQuery>(Queries.AwsS3PostCredentials, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}

export function useAccreditationDuplicateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<AccreditationDuplicateMutation, AccreditationDuplicateMutationVariables> {
    return useMutation(Mutations.AccreditationDuplicate, args);
}

export function useLoginCodeEmailCreateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<LoginCodeEmailCreateMutation, LoginCodeEmailCreateMutationVariables> {
    return useMutation(Mutations.LoginCodeEmailCreate, args);
}

export function usePositionDuplicateMutation(
    args: IUseMutationOptions = {}
): IUseMutationReturns<PositionDuplicateMutation, PositionDuplicateMutationVariables> {
    return useMutation(Mutations.PositionDuplicate, args);
}

export function useCurrentUserQuery(
    args: CurrentUserQueryVariables
): IUseQueryReturns<CurrentUserQuery> {
    return useQuery(Queries.CurrentUser, args, []);
}

export function executeCurrentUserQuery(
    args: CurrentUserQueryVariables,
    token: string | null | undefined
): Promise<CurrentUserQuery> {
    return executeQuery<CurrentUserQuery>(Queries.CurrentUser, {
        variables: args,
        token: token || undefined,
        fragments: []
    });
}
