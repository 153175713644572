import { injectable } from 'inversify';
import * as yup from 'yup';
import {
    ALL_MASSASSIGNSTRATEGY,
    ALL_MASSPREASSIGNLEVEL,
    MassPreAssignInput,
    MassPreAssignLevel
} from '../generated/types';
import { TranslationService } from '../services/translationService';
import { InputService } from './inputService';

export interface IMassPreAssignValues {
    massPreAssign: MassPreAssignInput;
}

@injectable()
export class MassPreAssignInputService extends InputService {
    constructor(protected translationService: TranslationService) {
        super(translationService);
    }

    massPreAssignStep1Schema() {
        return yup.object().shape({
            massPreAssign: yup.object().shape({
                ...this.massPreAssignStep1Fields()
            })
        });
    }

    massPreAssignStep2Schema() {
        return yup.object().shape({
            massPreAssign: yup.object().shape({
                ...this.massPreAssignStep2Fields()
            })
        });
    }

    massPreAssignSchema() {
        return yup.object().shape({
            ...this.massPreAssignStep1Fields(),
            ...this.massPreAssignStep2Fields()
        });
    }

    private massPreAssignStep1Fields() {
        return {
            strategy: yup
                .string()
                .required(this.t('la_strat_gie_es_82287'))
                .oneOf(ALL_MASSASSIGNSTRATEGY, this.t('la_strat_gie_es_82287')),
            level: yup
                .string()
                .required(this.t('le_niveau_est_r_81100'))
                .oneOf(ALL_MASSPREASSIGNLEVEL, this.t('le_niveau_est_r_81100'))
        };
    }

    private massPreAssignStep2Fields() {
        return {
            positionsCategoriesIds: yup
                .array()
                .of(yup.number())
                .test('non-empty-categories', this.t('vous_devez_s_le_32044'), function () {
                    if (this.parent.level === MassPreAssignLevel.Categories) {
                        return this.parent.positionsCategoriesIds.length > 0;
                    } else {
                        return true;
                    }
                }),
            positionsIds: yup
                .array()
                .of(yup.number())
                .test('non-empty-positions', this.t('vous_devez_s_le_89603'), function () {
                    if (this.parent.level === MassPreAssignLevel.Positions) {
                        return this.parent.positionsIds.length > 0;
                    } else {
                        return true;
                    }
                })
        };
    }
}
