import { getMimeType } from 'advanced-cropper/extensions/mimes';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { fileInfos } from 'common/src/util/file';
import * as React from 'react';
import { Cropper, CropperRef } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import { Accept, acceptExtensions, acceptString } from '../../../util/accept';
import { Button } from '../button';
import { IInputProps } from '../input/commonInputProps';
import { Modal } from '../modal';

interface IImageUploaderProps extends IInputProps {
    allowCrop?: boolean;

    onChange(file: File): void;
}

export interface Image {
    name: string;
    src: string;
    type?: string;
}

export const ImageUploader = ({
    allowCrop,
    onChange,
    hint,
    label,
    ...rest
}: IImageUploaderProps) => {
    const translate = useTranslate();

    const inputRef = React.useRef<HTMLInputElement>(null);

    const [isDraggingOver, setIsDraggingOver] = React.useState(false);
    const [image, setImage] = React.useState<Image | null>(null);
    const [showCropper, setShowCropper] = React.useState(false);

    const extensions = acceptExtensions(Accept.Images);

    const onLoadImage = (files: FileList | undefined) => {
        if (files && files[0]) {
            const blob = URL.createObjectURL(files[0]);
            // Remember the fallback type
            const typeFallback = files[0].type;

            const reader = new FileReader();
            reader.onload = (e) => {
                setImage({
                    src: blob,
                    type: getMimeType(e.target?.result, typeFallback),
                    name: files[0].name
                });
            };
            reader.readAsArrayBuffer(files[0]);
        }

        setShowCropper(true);
    };

    return (
        <>
            <Flex gap="4" width={1}>
                <Flex
                    align="center"
                    css={{
                        background: isDraggingOver ? '$primary50' : '$gray50',
                        border: isDraggingOver ? '1px solid $primary300' : '1px solid $gray200',
                        borderRadius: '$1',
                        cursor: 'pointer'
                    }}
                    height={126}
                    justify="center"
                    width={126}
                    onClick={() => {
                        inputRef.current?.click();
                    }}
                    onDragEnter={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        setIsDraggingOver(true);
                    }}
                    onDragLeave={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        setIsDraggingOver(false);
                    }}
                    onDragOver={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        if (!isDraggingOver) {
                            setIsDraggingOver(true);
                        }
                    }}
                    onDrop={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        setIsDraggingOver(false);

                        const file = e.dataTransfer.files.item(0);

                        if (file && extensions.includes(fileInfos(file.name).extension)) {
                            if (allowCrop) {
                                onLoadImage(e.dataTransfer.files);
                            } else {
                                onChange(file);
                            }
                        }
                    }}
                >
                    <Flex
                        align="center"
                        css={{
                            background: isDraggingOver ? '$primary100' : '$gray100',
                            borderRadius: '36px',
                            color: isDraggingOver ? '$primary600' : '$gray600'
                        }}
                        height={isDraggingOver ? 48 : 32}
                        justify="center"
                        width={isDraggingOver ? 48 : 32}
                    >
                        <I icon="plus" />
                    </Flex>
                </Flex>

                <Flex css={{ flex: '1' }} direction="column">
                    <Box color="gray700" fontWeight="medium">
                        {label}
                    </Box>

                    {hint && (
                        <>
                            <Spacer height="1" />

                            <Box color="gray400">{hint}</Box>
                        </>
                    )}

                    <Spacer height="3" />

                    <Flex>
                        <Button
                            onClick={() => {
                                inputRef.current?.click();
                            }}
                        >
                            {translate('s_lectionner_un_50712')}
                        </Button>
                    </Flex>
                </Flex>

                <Box css={{ display: 'none' }}>
                    <input
                        ref={inputRef}
                        accept={acceptString(Accept.Images)}
                        type="file"
                        onChange={(e) => {
                            if (e.target.files?.[0]) {
                                if (allowCrop) {
                                    onLoadImage(e.target.files);
                                } else {
                                    onChange(e.target.files[0]);
                                }
                            }
                        }}
                        {...rest}
                    />
                </Box>
            </Flex>

            {allowCrop && showCropper && image && (
                <ImageCropperModal
                    image={image}
                    onChange={onChange}
                    onClose={() => setShowCropper(false)}
                />
            )}
        </>
    );
};

interface IImageCropperModalProps {
    image: Image;

    onChange(file: File): void;
    onClose(): void;
}

export const ImageCropperModal = ({ image, onChange, onClose }: IImageCropperModalProps) => {
    const translate = useTranslate();

    const cropperRef = React.useRef<CropperRef>(null);

    const onUpload = () => {
        const canvas = cropperRef.current?.getCanvas();

        if (canvas) {
            canvas.toBlob(
                (blob) => {
                    if (blob) {
                        const file = new File([blob], image!.name, {
                            lastModified: new Date().getTime(),
                            type: image!.type
                        });

                        onChange(file);
                    }
                },
                image!.type,
                0.95
            );
        }

        onClose();
    };

    React.useEffect(
        () =>
            // Revoke the object URL, so the garbage collector can remove the original upload file
            () => {
                if (image && image.src) {
                    URL.revokeObjectURL(image.src);
                }
            },
        [image]
    );

    return (
        <Modal size="md" onClose={onClose}>
            <Flex css={{ overflow: 'auto' }} direction="column" gap="3">
                <Cropper
                    ref={cropperRef}
                    className="cropper"
                    src={image.src}
                    stencilProps={{
                        aspectRatio: 1
                    }}
                />

                <Button size="lg" textAlign="center" onClick={onUpload}>
                    {translate('submit_21373')}
                </Button>
            </Flex>
        </Modal>
    );
};
