import {
    AccreditationsSlotFragment,
    AccreditationsSlotId,
    EventId,
    MassAssignStrategy,
    UsersInfoId,
    VolunteerRegistrationFragment
} from 'common/src/generated/types';
import { sortBy, uniqBy } from 'lodash-es';
import * as React from 'react';
import { useVolunteersRegistrationsMassAccreditMutation } from '../../../generated/graphqlHooks';

export type AccreditationState = 'assigned' | 'wished';

interface IAccreditationsRightPanelContext {
    assignedAccreditations: Array<AccreditationsSlotFragment['accreditation']>;
    assignedCategories: Array<AccreditationsSlotFragment['accreditationCategory']>;
    assignedSlots: AccreditationsSlotFragment[];
    assignedSlotsIds: AccreditationsSlotId[];
    showActions: boolean;
    wishedAccreditations: Array<AccreditationsSlotFragment['accreditation']>;
    wishedCategories: Array<AccreditationsSlotFragment['accreditationCategory']>;
    wishedSlots: AccreditationsSlotFragment[];

    assignSlot(id: AccreditationsSlotId): void;
    deleteAssignedSlot(id: AccreditationsSlotId): void;
}

const AccreditationsRightPanelContext = React.createContext<IAccreditationsRightPanelContext>(
    {} as any
);

interface IAccreditationsRightPanelContextProviderProps {
    children: React.ReactNode;
    eventId: EventId;
    showActions: boolean;
    volunteerRegistration: Pick<
        VolunteerRegistrationFragment,
        'accreditationsUsersInfos' | 'accreditationsSlots'
    >;
    userInfoId: UsersInfoId;
}

export const AccreditationsRightPanelContextProvider = (
    props: IAccreditationsRightPanelContextProviderProps
) => {
    const { mutate: massAccredit } = useVolunteersRegistrationsMassAccreditMutation();
    const [assignedSlots, setAssignedSlots] = React.useState(
        props.volunteerRegistration.accreditationsUsersInfos.map((aui) => aui.accreditationSlot)
    );
    const assignedSlotsIds = React.useMemo(
        () => assignedSlots.map(({ id }) => id),
        [assignedSlots]
    );
    const [wishedSlots] = React.useState(props.volunteerRegistration.accreditationsSlots);
    const assignedCategories = React.useMemo(
        () =>
            sortBy(
                uniqBy(
                    assignedSlots.map((s) => s.accreditationCategory),
                    (c) => c.id
                ),
                (c) => c.name.toLowerCase()
            ),
        [assignedSlots]
    );
    const wishedCategories = React.useMemo(
        () =>
            sortBy(
                uniqBy(
                    wishedSlots.map((s) => s.accreditationCategory),
                    (c) => c.id
                ),
                (c) => c.name.toLowerCase()
            ),
        [wishedSlots]
    );
    const assignedAccreditations = React.useMemo(
        () =>
            uniqBy(
                assignedSlots.map((s) => s.accreditation),
                (a) => a.id
            ),
        [assignedSlots]
    );
    const wishedAccreditations = React.useMemo(
        () =>
            uniqBy(
                wishedSlots.map((s) => s.accreditation),
                (a) => a.id
            ),
        [wishedSlots]
    );
    const deleteAssignedSlot = React.useCallback(
        async (id: AccreditationsSlotId) => {
            await massAccredit({
                eventId: props.eventId,
                massAccredit: {
                    accreditationsSlotsIds: [id],
                    selecteds: {
                        ids: [props.userInfoId]
                    },
                    strategy: MassAssignStrategy.Delete
                }
            });

            setAssignedSlots((slots) => slots.filter((s) => s.id !== id));
        },
        [props.eventId, props.volunteerRegistration, setAssignedSlots]
    );
    const assignSlot = React.useCallback(
        async (id: AccreditationsSlotId) => {
            await massAccredit({
                eventId: props.eventId,
                massAccredit: {
                    accreditationsSlotsIds: [id],
                    selecteds: {
                        ids: [props.userInfoId]
                    },
                    strategy: MassAssignStrategy.Add
                }
            });

            const slot = wishedSlots.find((s) => s.id === id)!;
            setAssignedSlots((slots) => [...slots, slot]);
        },
        [props.eventId, props.volunteerRegistration, wishedSlots, setAssignedSlots]
    );

    return (
        <AccreditationsRightPanelContext.Provider
            value={{
                assignedAccreditations,
                assignedCategories,
                assignedSlots,
                assignedSlotsIds,
                showActions: props.showActions,
                wishedAccreditations,
                wishedCategories,
                wishedSlots,
                assignSlot,
                deleteAssignedSlot
            }}
        >
            {props.children}
        </AccreditationsRightPanelContext.Provider>
    );
};

export function useAccreditationsRightPanelContext() {
    return React.useContext(AccreditationsRightPanelContext);
}
