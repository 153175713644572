import { injectable } from 'inversify';
import * as yup from 'yup';
import { ALL_MASSASSIGNSTRATEGY, MassAccreditInput } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { InputService } from './inputService';

export interface IMassAccreditValues {
    massAccredit: MassAccreditInput;
}

@injectable()
export class MassAccreditInputService extends InputService {
    constructor(protected translationService: TranslationService) {
        super(translationService);
    }

    massAccreditStep1Schema() {
        return yup.object().shape({
            massAccredit: yup.object().shape({
                ...this.massAccreditStep1Fields()
            })
        });
    }

    massAccreditStep2Schema() {
        return yup.object().shape({
            massAccredit: yup.object().shape({
                ...this.massAccreditStep2Fields()
            })
        });
    }

    massAccreditSchema() {
        return yup.object().shape({
            ...this.massAccreditStep1Fields(),
            ...this.massAccreditStep2Fields()
        });
    }

    private massAccreditStep1Fields() {
        return {
            strategy: yup
                .string()
                .required(this.t('la_strat_gie_es_82287'))
                .oneOf(ALL_MASSASSIGNSTRATEGY, this.t('la_strat_gie_es_82287'))
        };
    }

    private massAccreditStep2Fields() {
        return {
            accreditationsSlotsIds: yup
                .array()
                .of(yup.number())
                .required(this.t('vous_devez_s_le_72138'))
                .min(1, this.t('vous_devez_s_le_72138'))
        };
    }
}
