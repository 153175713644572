import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    CustomBadge,
    DelegationMembersQuery,
    FormId,
    SegmentCustomFieldFragment,
    UsersInfoId
} from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { useService } from 'common/src/util/dependencies/dependencies';
import {
    getAccreditationsBadges,
    getWishedAccreditationsBadges
} from 'common/src/vo/accreditation';
import { FieldService } from 'common/src/vo/field';
import { getPositionsBadges, getWishedPositionsBadges } from 'common/src/vo/position';
import { PossibleColumn } from 'common/src/vo/segment';
import { VolunteerDefaultColumns } from 'common/src/vo/segments/volunteersSegmentsService';
import {
    getAccreditationStateBadgeColorIcon,
    getStateBadgeColorIcon
} from 'common/src/vo/volunteerRegistration';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AccreditationsRightPanel } from '../../../components/accreditations/rightPanel/accreditationsRightPanel';
import { BadgesPlus } from '../../../components/badgesPlus/badgesPlus';
import { Content } from '../../../designSystem/components/tooltip/content';
import { Tooltip } from '../../../designSystem/components/tooltip/tooltip';
import { Trigger } from '../../../designSystem/components/tooltip/trigger';
import { useReferrerContext } from '../../../util/referrerContext';
import { DelegationMemberRowDropdown } from './delegationMemberRowDropdown';

interface IDelegationMemberRowProps {
    basePath: string;
    canEditMembers: boolean;
    columns: PossibleColumn[];
    customBadges: CustomBadge[];
    customFields: SegmentCustomFieldFragment[];
    deleteFromEvent?: boolean;
    editPath: string;
    formId: FormId;
    isEventAdmin?: boolean;
    member: DelegationMembersQuery['organization']['usersInfos']['nodes'][number];
    showAccreditActions: boolean;
    showDeleteFromEvent: boolean;
    showDocumentsDownload: boolean;

    onClick?(memberId: UsersInfoId): void;
    reload(): void;
}

export const DelegationMemberRow = ({
    basePath,
    canEditMembers,
    columns,
    customBadges,
    customFields,
    deleteFromEvent,
    editPath,
    formId,
    isEventAdmin,
    member,
    onClick,
    showAccreditActions,
    showDeleteFromEvent,
    showDocumentsDownload,
    reload
}: IDelegationMemberRowProps) => {
    const {
        history,
        translate,
        params: { eventId }
    } = useHeavent();
    const { setReferrerPath } = useReferrerContext();

    const intervalService = useService(IntervalService);
    const fieldService = useService(FieldService);

    const accreditationsBadges = React.useMemo(
        () => getAccreditationsBadges(member.volunteerRegistration?.accreditationsUsersInfos || []),
        [member.volunteerRegistration?.accreditationsUsersInfos]
    );
    const wishedAccreditationsBadges = React.useMemo(
        () =>
            getWishedAccreditationsBadges(member.volunteerRegistration?.accreditationsSlots || []),
        [member.volunteerRegistration?.accreditationsSlots]
    );
    const positionsBadges = React.useMemo(
        () =>
            getPositionsBadges(
                intervalService,
                member.volunteerRegistration?.positionsSlotsUsersInfos || []
            ),
        [member.volunteerRegistration?.positionsSlotsUsersInfos]
    );
    const wishedPositionsBadges = React.useMemo(
        () =>
            getWishedPositionsBadges(
                intervalService,
                member.volunteerRegistration?.positionsCategories || [],
                member.volunteerRegistration?.positions || [],
                member.volunteerRegistration?.positionsSlots || []
            ),
        [member.volunteerRegistration]
    );
    const slugToCustomField = React.useMemo(
        () => new Map(customFields.map((cf) => [cf.slug, cf])),
        [customFields]
    );
    const accreditationPanelPath = `${basePath}/member-panel/${member.id}/accreditations`;

    return (
        <>
            <Row
                key={member.id}
                css={{
                    background: 'white',
                    cursor: onClick ? 'pointer' : 'default',
                    '&:hover': {
                        background: onClick ? '$gray100' : 'inherit'
                    }
                }}
                onClick={() => {
                    if (onClick) {
                        onClick(member.id);
                    }
                }}
            >
                {columns.map((column) => {
                    const customField = slugToCustomField.get(column.slug);
                    const vr = member.volunteerRegistration;

                    if (column.slug === VolunteerDefaultColumns.Name) {
                        return (
                            <Cell key={column.slug}>
                                <AvatarNameEmail hasName={true} userInfo={member} />
                            </Cell>
                        );
                    } else if (column.slug === VolunteerDefaultColumns.Email) {
                        return <Cell key={column.slug}>{member.email}</Cell>;
                    } else if (
                        vr &&
                        column.slug === VolunteerDefaultColumns.VolunteerRegistrationState
                    ) {
                        return (
                            <Cell
                                key={column.slug}
                                css={{
                                    border: '2px solid transparent'
                                }}
                                width={240}
                            >
                                <Badge {...getStateBadgeColorIcon(vr.state)}>
                                    {translate(vr.state)}
                                </Badge>
                            </Cell>
                        );
                    } else if (vr && column.slug === VolunteerDefaultColumns.AccreditationState) {
                        return (
                            <Cell
                                key={column.slug}
                                css={{
                                    border: '2px solid transparent'
                                }}
                                width={240}
                            >
                                <Badge
                                    {...getAccreditationStateBadgeColorIcon(vr.accreditationState)}
                                >
                                    {translate(vr.accreditationState)}
                                </Badge>
                            </Cell>
                        );
                    } else if (vr && column.slug === VolunteerDefaultColumns.Accreditations) {
                        return (
                            <Cell key={column.slug} css={{ border: '2px solid transparent' }}>
                                <BadgesPlus
                                    badges={accreditationsBadges}
                                    doNotSort={true}
                                    onClick={() => {
                                        history.push(`${accreditationPanelPath}/assigned`);
                                    }}
                                />
                            </Cell>
                        );
                    } else if (
                        vr &&
                        column.slug === VolunteerDefaultColumns.WishedAccreditationSlots
                    ) {
                        return (
                            <Cell key={column.slug} css={{ border: '2px solid transparent' }}>
                                <BadgesPlus
                                    badges={wishedAccreditationsBadges}
                                    doNotSort={true}
                                    onClick={() => {
                                        history.push(`${accreditationPanelPath}/wished`);
                                    }}
                                />
                            </Cell>
                        );
                    } else if (vr && column.slug === VolunteerDefaultColumns.Positions) {
                        return (
                            <Cell key={column.slug} css={{ border: '2px solid transparent' }}>
                                <BadgesPlus badges={positionsBadges} doNotSort={true} />
                            </Cell>
                        );
                    } else if (vr && column.slug === VolunteerDefaultColumns.WishedPositions) {
                        return (
                            <Cell key={column.slug} css={{ border: '2px solid transparent' }}>
                                <BadgesPlus badges={wishedPositionsBadges} doNotSort={true} />
                            </Cell>
                        );
                    } else {
                        return (
                            <Cell key={column.slug}>
                                {customField
                                    ? fieldService.getValueString(customField, member.fields, {
                                          fileReturnValue: 'name'
                                      })
                                    : ''}
                            </Cell>
                        );
                    }
                })}

                <CellControls justify="center">
                    {canEditMembers && (
                        <Tooltip>
                            <Trigger>
                                <Box>
                                    <I
                                        icon="pen"
                                        onClick={() => {
                                            setReferrerPath();
                                            history.push(editPath);
                                        }}
                                    />
                                </Box>
                            </Trigger>

                            <Content placement="top">{translate('_diter_62574')}</Content>
                        </Tooltip>
                    )}

                    <DelegationMemberRowDropdown
                        accreditationState={member.volunteerRegistration?.accreditationState}
                        canEditMembers={canEditMembers}
                        customBadges={customBadges}
                        deleteFromEvent={deleteFromEvent}
                        formId={formId}
                        member={member}
                        reload={reload}
                        showAccreditActions={showAccreditActions}
                        showDeleteFromEvent={showDeleteFromEvent}
                        showDocumentsDownload={showDocumentsDownload}
                    />
                </CellControls>
            </Row>

            {member.volunteerRegistration && (
                <Switch>
                    <Route path={accreditationPanelPath}>
                        <AccreditationsRightPanel
                            basePath={accreditationPanelPath}
                            eventId={eventId}
                            showActions={!!isEventAdmin}
                            userInfoId={member.id}
                            volunteerRegistration={member.volunteerRegistration}
                            onClose={() => history.push(basePath)}
                        />
                    </Route>
                </Switch>
            )}
        </>
    );
};
