import { isNonEmptyArray } from 'common/src/util/array';
import * as React from 'react';
import { useDelegationAccreditationsQuery } from '../../../generated/graphqlHooks';
import { useHeavent } from '../../../hooks/useHeavent';
import { DelegationAccreditations } from './delegationAccreditations';
import { DelegationAccreditationsBlank } from './delegationAccreditationsBlank';

interface IDelegationAccreditationsLoaderProps {
    isEventAdmin?: boolean;
}

export const DelegationAccreditationsLoader = (props: IDelegationAccreditationsLoaderProps) => {
    const {
        params: { eventId, delegationId }
    } = useHeavent();

    const { data, loader } = useDelegationAccreditationsQuery({
        eventId,
        delegationId,
        onlyVisible: !props.isEventAdmin
    });

    if (loader) {
        return loader;
    } else if (!isNonEmptyArray(data.event?.delegation.accreditationsSlots)) {
        return <DelegationAccreditationsBlank />;
    } else {
        return <DelegationAccreditations event={data.event} isEventAdmin={props.isEventAdmin} />;
    }
};
