import { injectable } from 'inversify';
import * as yup from 'yup';
import { ALL_MASSASSIGNSTRATEGY, MassAssignInput } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { InputService } from './inputService';

export interface IMassAssignValues {
    massAssign: MassAssignInput;
}

@injectable()
export class MassAssignInputService extends InputService {
    constructor(protected translationService: TranslationService) {
        super(translationService);
    }

    massAssignStep1Schema() {
        return yup.object().shape({
            massAssign: yup.object().shape({
                ...this.massAssignStep1Fields()
            })
        });
    }

    massAssignStep2Schema() {
        return yup.object().shape({
            massAssign: yup.object().shape({
                ...this.massAssignStep2Fields()
            })
        });
    }

    massAssignSchema() {
        return yup.object().shape({
            ...this.massAssignStep1Fields(),
            ...this.massAssignStep2Fields()
        });
    }

    private massAssignStep1Fields() {
        return {
            strategy: yup
                .string()
                .required(this.t('la_strat_gie_es_82287'))
                .oneOf(ALL_MASSASSIGNSTRATEGY, this.t('la_strat_gie_es_82287'))
        };
    }

    private massAssignStep2Fields() {
        return {
            positionsSlotsIds: yup
                .array()
                .of(yup.number())
                .required(this.t('vous_devez_s_le_28976'))
                .min(1, this.t('vous_devez_s_le_28976'))
        };
    }
}
