import { injectable } from 'inversify';
import { camelCase } from 'lodash-es';
import { parse } from 'papaparse';
import * as yup from 'yup';
import { TranslationService } from '../services/translationService';
import { isNonEmptyArray } from '../util/array';
import { fileInfos } from '../util/file';
import { MimeType, mimeType } from '../util/mimeType';
import { isNonEmptyString } from '../util/string';
import { InputService } from './inputService';

export const MAX_CSV_SIZE = 20_000_000; // 20 MB. Arbitrary.

const hasEmptyColumnHeaders = (data: string[][]) =>
    data[0].filter((column) => !isNonEmptyString(column)).length > 0;

const hasMissingRequiredColumnHeaders = (data: string[][], columnValues: string[]) =>
    !columnValues.reduce((acc, column) => acc && data[0].map(camelCase).includes(column), true);

const hasUnexpectedColumnHeaders = (data: string[][], columnValues: string[]) =>
    data[0].filter((column) => !columnValues.includes(column)).length > 0;

@injectable()
export class CsvInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    schema({
        defaultColumns,
        requiredColumns,
        hasCustomFields = true
    }: {
        defaultColumns?: Record<string, string>;
        requiredColumns?: string[];
        hasCustomFields?: boolean;
    }) {
        const required = this.t('le_document_est_86403');

        const defaultColumnValues = defaultColumns ? Object.values(defaultColumns) : undefined;

        return yup.object().shape({
            acl: yup.string().required(required),
            key: yup
                .string()
                .required(required)
                .test(
                    'is-a-csv',
                    this.t('le_fichier_n_es_33134'),
                    (key: string) => mimeType(fileInfos(key).extension) === MimeType.Csv
                ),
            name: yup.string().required(required),
            file: yup
                .mixed()
                .test('is-not-too-big', this.t('le_fichier_est_17522'), (file) =>
                    file ? (file as File).size < MAX_CSV_SIZE : true
                )
                .test({
                    name: 'header-contains-all-required-default-columns',
                    test: (file, { createError, path }) => {
                        if (!file || !isNonEmptyArray(requiredColumns)) {
                            return true;
                        } else {
                            return new Promise((resolve) => {
                                parse<string[]>(file as File, {
                                    preview: 1,
                                    complete: ({ data, errors }) => {
                                        if (errors.length || hasEmptyColumnHeaders(data)) {
                                            resolve(
                                                createError({
                                                    message: this.t('le_fichier_csv_14924'),
                                                    path
                                                })
                                            );
                                        } else if (
                                            hasMissingRequiredColumnHeaders(data, requiredColumns)
                                        ) {
                                            resolve(
                                                createError({
                                                    message: this.t(
                                                        'l_en_t_te_du_fi_79224',
                                                        this.translationService.formatListValues(
                                                            requiredColumns
                                                        )
                                                    ),
                                                    path
                                                })
                                            );
                                        } else {
                                            resolve(true);
                                        }
                                    }
                                });
                            });
                        }
                    }
                })
                // TODO - how can this be changed to handle custom field columns?
                .test({
                    name: 'header-contains-no-unexpected-columns',
                    test: (file, { createError, path }) => {
                        if (!file || !isNonEmptyArray(defaultColumnValues) || hasCustomFields) {
                            return true;
                        } else {
                            return new Promise((resolve) => {
                                parse<string[]>(file as File, {
                                    preview: 1,
                                    complete: ({ data, errors }) => {
                                        if (errors.length || hasEmptyColumnHeaders(data)) {
                                            resolve(
                                                createError({
                                                    message: this.t('le_fichier_csv_14924'),
                                                    path
                                                })
                                            );
                                        } else if (
                                            hasUnexpectedColumnHeaders(data, defaultColumnValues)
                                        ) {
                                            resolve(
                                                createError({
                                                    message: this.t(
                                                        'the_csv_file_he_91455',
                                                        this.translationService.formatListValues(
                                                            data[0].filter(
                                                                (columnName) =>
                                                                    !defaultColumnValues.includes(
                                                                        columnName
                                                                    )
                                                            )
                                                        )
                                                    ),
                                                    path
                                                })
                                            );
                                        } else {
                                            resolve(true);
                                        }
                                    }
                                });
                            });
                        }
                    }
                })
        });
    }
}
