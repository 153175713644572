import { Flex } from 'common/src/designSystem/components/flex';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useCalendarContext } from './root';

interface IEventProps {
    children: React.ReactNode;
    startAt?: DateTime;
    endAt?: DateTime;
}

export const Event = (props: IEventProps) => {
    const { days, interval } = useCalendarContext();
    const firstDay = days[0];
    const lastDay = days[6];
    const startAt = (props.startAt ?? DateTime.max(firstDay, interval.start!)).startOf('day');
    const endAt = (props.endAt ?? DateTime.min(lastDay, interval.end!)).endOf('day');
    const gridColumnStart = Math.max(startAt.diff(firstDay, 'days').days + 1, 1);
    const gridColumnEnd = Math.min(Math.ceil(endAt.diff(firstDay, 'days').days) + 1, 8);

    if (startAt > lastDay || endAt < firstDay) {
        return null;
    } else {
        return (
            <Flex
                css={{
                    gridColumnStart,
                    gridColumnEnd,
                    px: '$2'
                }}
            >
                {props.children}
            </Flex>
        );
    }
};
