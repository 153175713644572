import { FormErrors } from 'common-front/src/components/form/formErrors';
import { UserCreateForm } from 'common-front/src/components/users/create/userCreateForm';
import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { FormElementDisplayFragment } from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { Fields } from 'common/src/vo/field';
import * as React from 'react';

interface IFormInformationsProps {
    countryCode: Emptyable<string>;
    elements: FormElementDisplayFragment[];
    isFinalStep: boolean;
    registerButtonText: string;
    values: Fields;

    change(name: string, value: any): void;
    handleSubmit(): void;
}

export const FormInformations = (props: IFormInformationsProps) => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();

    return (
        <>
            <FormErrors />

            <UserCreateForm
                countryCode={props.countryCode}
                elements={props.elements}
                prefix="formRegister.userInfo.fields."
                values={props.values}
                organizationId={organizationId}
                change={props.change}
            />

            <Spacer height={{ '@initial': '6', '@tablet': '7' }} />

            <Flex>
                <Button onClick={props.handleSubmit}>
                    {props.isFinalStep ? props.registerButtonText : translate('suivant_62774')}
                </Button>
            </Flex>
        </>
    );
};
