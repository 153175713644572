import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import {
    FormMissionsOptionsFragment,
    FormMissionsQuery,
    PositionId
} from 'common/src/generated/types';
import { addOrRemove } from 'common/src/util/array';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { CheckboxText } from '../../../designSystem/components/checkbox';
import { FormMissionDescriptionModal } from '../formMissionDescriptionModal';

interface IFormMissionsPositionProps {
    options: FormMissionsOptionsFragment;
    position: FormMissionsQuery['event']['positionsCategories'][0]['positions'][0];
    positionsIds: PositionId[];
    prefix: string;

    change(name: string, value: any): void;
}

export const FormMissionsPositionsPosition = (props: IFormMissionsPositionProps) => {
    const [isDescriptionOpen, setIsDescriptionOpen] = React.useState(false);
    const showDescription =
        isNonEmptyString(props.position.description) && props.options.showPositionDescription;

    return (
        <>
            <CheckboxText
                css={{ alignItems: 'center' }}
                state={props.positionsIds.includes(props.position.id) ? 'checked' : 'unchecked'}
                onClick={(state) => {
                    props.change(
                        `${props.prefix}positionsIds`,
                        addOrRemove(props.positionsIds, props.position.id, state === 'checked')
                    );
                }}
            >
                <Flex align="center" gap="2">
                    <Box>{props.position.name}</Box>

                    {showDescription && (
                        <Box
                            onClick={(e) => {
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                                setIsDescriptionOpen(true);
                            }}
                        >
                            <I icon="circle-info" />
                        </Box>
                    )}
                </Flex>
            </CheckboxText>

            {isDescriptionOpen && (
                <FormMissionDescriptionModal
                    description={props.position.description}
                    name={props.position.name}
                    onClose={() => {
                        setIsDescriptionOpen(false);
                    }}
                />
            )}
        </>
    );
};
