import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    FormMissionsOptionsFragment,
    FormRegisterPositionFragment,
    FormRegisterSlotFragment,
    PositionsSlotId,
    VolunteersRegistrationsSlotInput
} from 'common/src/generated/types';
import { isNonEmptyString } from 'common/src/util/string';
import { canSelectV2 } from 'common/src/vo/positionSlot';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { FormMissionDescriptionModal } from '../formMissionDescriptionModal';
import { FormMissionsSlotsSlot } from './formMissionsSlotsSlot';

interface IFormMissionsSlotsPositionProps {
    allSlots: FormRegisterSlotFragment[];
    options: FormMissionsOptionsFragment;
    position: FormRegisterPositionFragment;
    positionsSlotsIds: PositionsSlotId[];
    prefix: string;
    slots: VolunteersRegistrationsSlotInput[];

    change(name: string, value: any): void;
}

export const FormMissionsSlotsPosition = (props: IFormMissionsSlotsPositionProps) => {
    const slots = React.useMemo(
        () =>
            sortBy(
                props.position.slots.filter((slot) =>
                    canSelectV2(slot, props.slots, props.options)
                ),
                [(s) => s.range.start!.toMillis(), (s) => s.nameOrRange]
            ),
        [props.position, props.slots, props.options]
    );
    const [isDescriptionOpen, setIsDescriptionOpen] = React.useState(false);
    const showDescription =
        isNonEmptyString(props.position.description) && props.options.showPositionDescription;

    return (
        <>
            <Spacer height="2" />

            <Flex align="center" gap="2">
                <Box color="gray900" fontWeight="semiBold">
                    {props.position.name}
                </Box>

                {showDescription && (
                    <Box
                        color="gray700"
                        onClick={() => {
                            setIsDescriptionOpen(true);
                        }}
                    >
                        <I icon="circle-info" />
                    </Box>
                )}
            </Flex>

            <Spacer height="2" />

            {slots.map((slot, slotIndex) => (
                <FormMissionsSlotsSlot
                    key={slot.id}
                    allSlots={props.allSlots}
                    change={props.change}
                    index={slotIndex}
                    options={props.options}
                    positionsSlotsIds={props.positionsSlotsIds}
                    prefix={props.prefix}
                    slot={slot}
                />
            ))}

            {isDescriptionOpen && (
                <FormMissionDescriptionModal
                    description={props.position.description}
                    name={props.position.name}
                    onClose={() => {
                        setIsDescriptionOpen(false);
                    }}
                />
            )}
        </>
    );
};
