import { kebabCase } from 'lodash-es';
import * as React from 'react';
import { Icon } from '../../generated/types';
import { ILinkProps, Link } from './link';

export type IStyle = 'solid' | 'regular' | 'light' | 'thin' | 'duotone';

export type IIcon =
    | '00'
    | 'address-card'
    | 'alarm-clock'
    | 'align-center'
    | 'align-left'
    | 'align-right'
    | 'angle-down'
    | 'angle-up'
    | 'arrow-down'
    | 'arrow-down-arrow-up'
    | 'arrow-down-to-bracket'
    | 'arrow-left'
    | 'arrow-left-from-line'
    | 'arrow-right'
    | 'arrow-right-arrow-left'
    | 'arrow-right-from-bracket'
    | 'arrow-right-from-line'
    | 'arrow-trend-up'
    | 'arrow-up'
    | 'arrow-up-arrow-down'
    | 'arrow-up-right-from-square'
    | 'arrows-repeat'
    | 'arrows-rotate'
    | 'arrows-to-dot'
    | 'at'
    | 'backpack'
    | 'badge'
    | 'badge-check'
    | 'bag-shopping'
    | 'bars'
    | 'bars-filter'
    | 'bed'
    | 'bolt'
    | 'briefcase-medical'
    | 'building'
    | 'burger-soda'
    | 'cake-candles'
    | 'calendar'
    | 'calendar-day'
    | 'calendar-range'
    | 'camera'
    | 'car'
    | 'certificate'
    | 'check'
    | 'cherries'
    | 'chevron-down'
    | 'chevron-left'
    | 'chevron-right'
    | 'chevron-up'
    | 'circle'
    | 'circle-check'
    | 'circle-exclamation'
    | 'circle-info'
    | 'circle-parking'
    | 'circle-question'
    | 'circle-xmark'
    | 'clock'
    | 'clone'
    | 'cloud-arrow-down'
    | 'cloud-arrow-up'
    | 'code-branch'
    | 'comments'
    | 'copy'
    | 'crown'
    | 'download'
    | 'droplet'
    | 'ellipsis'
    | 'ellipsis-vertical'
    | 'envelope'
    | 'expand'
    | 'eye'
    | 'eye-slash'
    | 'face-frown'
    | 'file'
    | 'file-export'
    | 'file-import'
    | 'file-invoice'
    | 'file-lines'
    | 'flag'
    | 'folder'
    | 'folders'
    | 'fork-knife'
    | 'frame'
    | 'gear'
    | 'globe'
    | 'grid-2'
    | 'grip-vertical'
    | 'hand-holding-heart'
    | 'hand-pointer'
    | 'hat-cowboy'
    | 'hourglass-empty'
    | 'house'
    | 'id-card'
    | 'image'
    | 'infinity'
    | 'language'
    | 'layer-group'
    | 'lightbulb-on'
    | 'list'
    | 'list-check'
    | 'list-ul'
    | 'loader'
    | 'location-dot'
    | 'lock'
    | 'lock-keyhole'
    | 'lock-keyhole-open'
    | 'magnifying-glass'
    | 'map-location'
    | 'martini-glass'
    | 'megaphone'
    | 'memo-circle-info'
    | 'merge'
    | 'message'
    | 'minus'
    | 'mobile'
    | 'objects-align-bottom'
    | 'objects-align-center-vertical'
    | 'objects-align-top'
    | 'paper-plane'
    | 'pen'
    | 'pen-field'
    | 'pen-ruler'
    | 'people-group'
    | 'person-biking'
    | 'person-hiking'
    | 'person-skiing'
    | 'phone'
    | 'plus'
    | 'receipt'
    | 'rotate-left'
    | 'route'
    | 'scanner-gun'
    | 'search'
    | 'shield'
    | 'shirt'
    | 'sidebar'
    | 'signs-post'
    | 'sitemap'
    | 'sliders'
    | 'snowflake'
    | 'sort'
    | 'sort-down'
    | 'sort-up'
    | 'sparkles'
    | 'split'
    | 'square'
    | 'square-check'
    | 'star'
    | 'table'
    | 'table-cells'
    | 'tag'
    | 'text'
    | 'ticket'
    | 'trash-can'
    | 'triangle-exclamation'
    | 'user'
    | 'user-check'
    | 'user-gear'
    | 'user-group'
    | 'user-plus'
    | 'user-police'
    | 'user-shield'
    | 'user-slash'
    | 'user-xmark'
    | 'users'
    | 'ustensils'
    | 'venus-mars'
    | 'wand-magic-sparkles'
    | 'wave-pulse'
    | 'xmark';

export function fromIconVO(icon: Icon): IIcon {
    return kebabCase(icon as string) as IIcon;
}

interface IIProps extends Partial<Omit<ILinkProps, 'children' | 'onClick'>> {
    className?: string;
    icon: IIcon;
    iconStyle?: IStyle;
    primaryColor?: string;
    secondaryColor?: string;
    title?: string;

    onClick?(e: React.MouseEvent<any>): void;
}

export const I = (props: IIProps) => {
    const iconStyle = props.iconStyle ?? 'regular';
    const styles: any = {};

    if (props.primaryColor) {
        styles['--fa-primary-color'] = props.primaryColor;
    }

    if (props.secondaryColor) {
        styles['--fa-secondary-color'] = props.secondaryColor;
        styles['--fa-secondary-opacity'] = '1';
    }

    const renderElement = () => (
        <i
            className={`fa-${iconStyle} fa-${props.icon} ${props.className || ''}`}
            style={{
                ...styles,
                cursor: props.title ? 'help' : 'inherit'
            }}
            title={props.title}
            onClick={props.to ? undefined : props.onClick}
        />
    );

    return props.to ? (
        <Link target={props.target} to={props.to} onClick={props.onClick}>
            {renderElement()}
        </Link>
    ) : (
        renderElement()
    );
};
