import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I, IIcon } from 'common/src/designSystem/components/i';
import * as React from 'react';

interface IDelegationAccreditationCountProps {
    background: string;
    count: number | string;
    display: 'horizontal' | 'vertical';
    icon: IIcon;
    text: string;
}

export const DelegationAccreditationCount = (props: IDelegationAccreditationCountProps) => {
    const textCss = props.display === 'horizontal' ? {} : { flex: '1' };

    return (
        <Flex align="center" gap="2" width={props.display === 'horizontal' ? undefined : 1}>
            <Box>
                <I icon={props.icon} />
            </Box>

            <Box css={textCss} fontSize="textXs">
                {props.text}
            </Box>

            <Flex
                align="center"
                css={{
                    background: props.background,
                    borderRadius: '$1',
                    fontSize: '$textXs',
                    fontWeight: '$medium',
                    padding: '2px 8px',
                    minWidth: '30px'
                }}
                justify="center"
            >
                {props.count}
            </Flex>
        </Flex>
    );
};
