import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { DelegationAccreditationSlotStatsFragment } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IDelegationAccreditationSlotStatsProps {
    isLoading: boolean;
    isWeezeventConnected: boolean;
    stats: DelegationAccreditationSlotStatsFragment;
}

export const DelegationAccreditationSlotStats = (props: IDelegationAccreditationSlotStatsProps) => {
    const translate = useTranslate();

    return (
        <Flex
            css={{
                background: 'white',
                bd: true,
                padding: '$4 $6'
            }}
            direction="column"
            gap="2"
        >
            {props.isLoading ? (
                <>
                    <Skeleton borderRadius="$1" height={20} width={1} />
                    <Skeleton borderRadius="$1" height={20} width={1} />
                    {props.isWeezeventConnected && (
                        <Skeleton borderRadius="$1" height={20} width={1} />
                    )}
                </>
            ) : (
                <>
                    <Flex align="center" css={{ color: '$gray700' }} gap="1" width={1}>
                        <Box>
                            <I icon="badge-check" />
                        </Box>

                        <Box css={{ flex: '1' }}>{translate('attribu_es_48167')}</Box>

                        <Box>{props.stats.assignedResources}</Box>
                    </Flex>

                    <Flex align="center" css={{ color: '$gray700' }} gap="1" width={1}>
                        <Box>
                            <I icon="hand-holding-heart" />
                        </Box>

                        <Box css={{ flex: '1' }}>{translate('souhait_es_78101')}</Box>

                        <Box>{props.stats.wishedResources}</Box>
                    </Flex>

                    {props.isWeezeventConnected && (
                        <Flex align="center" css={{ color: '$gray700' }} gap="1" width={1}>
                            <Box>
                                <I icon="scanner-gun" />
                            </Box>

                            <Box css={{ flex: '1' }}>{translate('scann_es_13169')}</Box>

                            <Box>{props.stats.scannedResources}</Box>
                        </Flex>
                    )}
                </>
            )}
        </Flex>
    );
};
