import { PathComponents } from './paths/commonPaths';
import {
    AccreditationIdPathParam,
    BadgeTypePathParam,
    CampaignIdPathParam,
    CustomDocumentIdPathParam,
    CustomFieldIdPathParam,
    CustomFieldSlugPathParam,
    DelegationIdPathParam,
    EmailSenderIdPathParam,
    EventIdPathParam,
    FormIdPathParam,
    OrganizationIdPathParam,
    PathParam,
    PositionCategoryIdPathParam,
    PositionIdPathParam,
    PositionSlotIdPathParam,
    SegmentIdPathParam,
    TeamCodePathParam,
    UserInfoIdPathParam,
    VolunteerRegistrationIdPathParam,
    WeezeventRuleIdPathParam
} from './pathsTypes';

const AUTH = '/auth';
const AUTH_SIGN_IN = `${AUTH}/sign-in`;
const AUTH_SIGN_UP = `${AUTH}/sign-up`;
const BACKOFFICE = `/backoffice`;
const ORGANIZATION = (id: OrganizationIdPathParam) => `/organization/${id}`;
const ORGANIZATION_PARAMETERS = (organizationId: OrganizationIdPathParam) =>
    `${ORGANIZATION(organizationId)}/parameters`;
const EVENT = (organizationId: OrganizationIdPathParam, id: EventIdPathParam) =>
    `${ORGANIZATION(organizationId)}/event/${id}`;
const POSITION_CATEGORY = (
    organizationId: OrganizationIdPathParam,
    eventId: EventIdPathParam,
    positionCategoryId: PositionCategoryIdPathParam
) => `${EVENT(organizationId, eventId)}/category/${positionCategoryId}`;
const POSITIONS = (organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) =>
    `${EVENT(organizationId, eventId)}/positions`;
const POSITIONS_SEARCH = (organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) =>
    `${POSITIONS(organizationId, eventId)}/search`;
const POSITIONS_SEGMENT = (
    organizationId: OrganizationIdPathParam,
    eventId: EventIdPathParam,
    segmentId: SegmentIdPathParam
) => `${POSITIONS(organizationId, eventId)}/${segmentId}`;
const POSITION_MAP = (eventId: EventIdPathParam, positionId: PositionIdPathParam) =>
    `/position/${eventId}-${positionId}/map`;
const EXPORT_POSITIONS_MAP = (eventId: EventIdPathParam, segmentId: SegmentIdPathParam) =>
    `/export/event/${eventId}/positions/${segmentId}/map`;
const POSITION = (
    organizationId: OrganizationIdPathParam,
    eventId: EventIdPathParam,
    positionId: PositionIdPathParam
) => `${EVENT(organizationId, eventId)}/position/${positionId}`;
const POSITION_MEMBERS = (
    organizationId: OrganizationIdPathParam,
    eventId: EventIdPathParam,
    positionCategoryId: PositionCategoryIdPathParam,
    positionId: PositionIdPathParam
) =>
    `${POSITION_CATEGORY(
        organizationId,
        eventId,
        positionCategoryId
    )}/position/${positionId}/members`;
const POSITION_SLOT = (
    organizationId: OrganizationIdPathParam,
    eventId: EventIdPathParam,
    positionId: PositionIdPathParam,
    positionSlotId: PositionSlotIdPathParam
) => `${POSITION(organizationId, eventId, positionId)}/slot/${positionSlotId}`;
const POSITION_SLOT_CHECK_MEMBERS = (
    organizationId: OrganizationIdPathParam,
    eventId: EventIdPathParam,
    positionId: PositionIdPathParam,
    positionSlotId: PositionSlotIdPathParam
) => `${POSITION_SLOT(organizationId, eventId, positionId, positionSlotId)}/check-members`;
const POSITION_SLOT_MEMBERS = (
    organizationId: OrganizationIdPathParam,
    eventId: EventIdPathParam,
    positionId: PositionIdPathParam,
    positionSlotId: PositionSlotIdPathParam
) => `${POSITION_SLOT(organizationId, eventId, positionId, positionSlotId)}/members`;
const POSITION_SLOT_ASSIGN_VOLUNTEERS = (
    organizationId: OrganizationIdPathParam,
    eventId: EventIdPathParam,
    positionId: PositionIdPathParam,
    positionSlotId: PositionSlotIdPathParam
) => `${POSITION_SLOT(organizationId, eventId, positionId, positionSlotId)}/assign-volunteers`;
const POSITION_SLOT_ASSIGN_VOLUNTEERS_USER = (
    organizationId: OrganizationIdPathParam,
    eventId: EventIdPathParam,
    positionId: PositionIdPathParam,
    positionSlotId: PositionSlotIdPathParam,
    userInfoId: UserInfoIdPathParam
) =>
    `${POSITION_SLOT(
        organizationId,
        eventId,
        positionId,
        positionSlotId
    )}/assign-volunteers/${userInfoId}`;
const VOLUNTEERS = (organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) =>
    `${EVENT(organizationId, eventId)}/volunteers`;
const VOLUNTEERS_SEARCH = (organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) =>
    `${VOLUNTEERS(organizationId, eventId)}/search`;
const VOLUNTEERS_SEARCH_USER = (
    organizationId: OrganizationIdPathParam,
    eventId: EventIdPathParam,
    userInfoId: UserInfoIdPathParam
) => `${VOLUNTEERS_SEARCH(organizationId, eventId)}/volunteer/${userInfoId}`;
const VOLUNTEERS_SEGMENT = (
    organizationId: OrganizationIdPathParam,
    eventId: EventIdPathParam,
    segmentId: SegmentIdPathParam
) => `${VOLUNTEERS(organizationId, eventId)}/${segmentId}`;
const VOLUNTEERS_LIST_USER = (
    organizationId: OrganizationIdPathParam,
    eventId: EventIdPathParam,
    segmentId: SegmentIdPathParam,
    userInfoId: UserInfoIdPathParam
) => `${VOLUNTEERS_SEGMENT(organizationId, eventId, segmentId)}/volunteer/${userInfoId}`;
const VOLUNTEERS_LIST_USER_PANEL = (
    organizationId: OrganizationIdPathParam,
    eventId: EventIdPathParam,
    segmentId: SegmentIdPathParam,
    userInfoId: UserInfoIdPathParam
) => `${VOLUNTEERS_SEGMENT(organizationId, eventId, segmentId)}/volunteer-panel/${userInfoId}`;
const EVENT_EMAILS = (organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) =>
    `${EVENT(organizationId, eventId)}/emails`;
const FORMS = (organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) =>
    `${EVENT(organizationId, eventId)}/forms`;
const FORM = (
    organizationId: OrganizationIdPathParam,
    eventId: EventIdPathParam,
    formId: FormIdPathParam
) => `${EVENT(organizationId, eventId)}/form/${formId}`;
const FORM_PARAMETERS = (
    organizationId: OrganizationIdPathParam,
    eventId: EventIdPathParam,
    formId: FormIdPathParam
) => `${FORM(organizationId, eventId, formId)}/parameters`;
const INTEGRATION = (organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) =>
    `${EVENT(organizationId, eventId)}/integration`;
const CAMPAIGN = (
    organizationId: OrganizationIdPathParam,
    eventId: EventIdPathParam,
    campaignId: CampaignIdPathParam
) => `${EVENT(organizationId, eventId)}/campaign/${campaignId}`;
const DOCUMENTS = (organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) =>
    `${EVENT(organizationId, eventId)}/documents`;
const API = (organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) =>
    `${EVENT(organizationId, eventId)}/api`;
const ACCREDITATIONS = (organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) =>
    `${EVENT(organizationId, eventId)}/accreditations`;
const ACCREDITATIONS_SEGMENT = (
    organizationId: OrganizationIdPathParam,
    eventId: EventIdPathParam,
    segmentId: SegmentIdPathParam
) => `${ACCREDITATIONS(organizationId, eventId)}/${segmentId}`;
const ACCREDITATION = (
    organizationId: OrganizationIdPathParam,
    eventId: EventIdPathParam,
    accreditationId: AccreditationIdPathParam
) => `${EVENT(organizationId, eventId)}/accreditation/${accreditationId}`;
const ACCREDITATION_ACCREDIT = (
    organizationId: OrganizationIdPathParam,
    eventId: EventIdPathParam,
    accreditationId: AccreditationIdPathParam
) => `${ACCREDITATION(organizationId, eventId, accreditationId)}/accredit`;
const ACCREDITATION_ACCREDIT_USER = (
    organizationId: OrganizationIdPathParam,
    eventId: EventIdPathParam,
    accreditationId: AccreditationIdPathParam,
    userInfoId: UserInfoIdPathParam
) => `${ACCREDITATION_ACCREDIT(organizationId, eventId, accreditationId)}/user/${userInfoId}`;
const DASHBOARD = (organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) =>
    `${EVENT(organizationId, eventId)}/dashboard`;
const DELEGATIONS = (organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) =>
    `${EVENT(organizationId, eventId)}/delegations`;
const DELEGATIONS_SEGMENT = (
    organizationId: OrganizationIdPathParam,
    eventId: EventIdPathParam,
    segmentId: SegmentIdPathParam
) => `${DELEGATIONS(organizationId, eventId)}/${segmentId}`;
const DELEGATION = (
    organizationId: OrganizationIdPathParam,
    eventId: EventIdPathParam,
    delegationId: DelegationIdPathParam
) => `${EVENT(organizationId, eventId)}/delegation/${delegationId}`;

export const HeaventPaths = {
    HOME: '/',
    UNSUBSCRIBE_EMAIL: '/unsubscribe-email',
    USER_FIELD_UPDATE(
        organizationId: OrganizationIdPathParam,
        userInfoId: UserInfoIdPathParam,
        slug: CustomFieldSlugPathParam,
        value: string
    ) {
        return `/organization/${organizationId}/user/${userInfoId}/field/${slug}/update/${value}`;
    },

    // sign in / sign up
    AUTH,
    AUTH_FORGOT_PASSWORD: `${AUTH}/forgot-password`,
    AUTH_SIGN_IN,
    AUTH_SIGN_UP,
    AUTH_SIGN_IN_EMAIL: `${AUTH_SIGN_IN}/email`,
    AUTH_SIGN_IN_CODE: `${AUTH_SIGN_IN}/code`,
    AUTH_SIGN_UP_CREDENTIALS: `${AUTH_SIGN_UP}/credentials`,
    AUTH_SIGN_UP_CODE: `${AUTH_SIGN_UP}/code`,
    COGNITO_CALLBACK: '/cognito-callback',
    MAGIC_LINK(code: PathParam, source: PathParam) {
        return `/magic-link/${code}/${source}`;
    },

    // terms and conditions
    ACCEPT_TERMS_AND_CONDITIONS(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION(organizationId)}/accept-terms-and-conditions`;
    },

    // back office
    BACKOFFICE,
    BACKOFFICE_ORGANIZATIONS: `${BACKOFFICE}/organizations`,
    EDIT_BACKOFFICE_ORGANIZATION(id: OrganizationIdPathParam) {
        return `${BACKOFFICE}/organization/${id}/edit`;
    },
    BACKOFFICE_INVOICES: `${BACKOFFICE}/invoices`,

    // organization
    ORGANIZATION,

    // organization parameters
    ORGANIZATION_PARAMETERS,
    ORGANIZATION_PARAMETERS_PROFILE(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/profile`;
    },
    ORGANIZATION_PARAMETERS_INFOS(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/general`;
    },
    ORGANIZATION_PARAMETERS_TEAM(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/team`;
    },
    ORGANIZATION_PARAMETERS_EMAILS(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/emails`;
    },
    CREATE_ORGANIZATION_PARAMETERS_EMAILS_SENDERS(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/emails/senders/create`;
    },
    EDIT_ORGANIZATION_PARAMETERS_EMAILS_SENDERS(
        organizationId: OrganizationIdPathParam,
        emailSenderId: EmailSenderIdPathParam
    ) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/emails/senders/${emailSenderId}/edit`;
    },
    ORGANIZATION_PARAMETERS_EMAILS_SENDERS(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/emails/senders`;
    },
    ORGANIZATION_PARAMETERS_EMAILS_SENDERS_PREVIEW(
        organizationId: OrganizationIdPathParam,
        emailSenderId: EmailSenderIdPathParam
    ) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/emails/senders/${emailSenderId}/preview`;
    },
    ORGANIZATION_PARAMETERS_EMAILS_DNS(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/emails/dns`;
    },
    ORGANIZATION_PARAMETERS_DELEGATIONS_SPACE(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/delegations-space`;
    },
    ORGANIZATION_PARAMETERS_DELEGATIONS_SPACE_DESIGN(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/delegations-space/design`;
    },
    ORGANIZATION_PARAMETERS_DELEGATIONS_SPACE_INTEGRATION(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/delegations-space/integration`;
    },
    ORGANIZATION_PARAMETERS_DELEGATIONS_SPACE_EMAILS(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/delegations-space/emails`;
    },
    ORGANIZATION_PARAMETERS_CATEGORIES(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/categories`;
    },
    ORGANIZATION_PARAMETERS_CATEGORIES_CUSTOM_FIELDS(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/categories/custom-fields`;
    },
    ORGANIZATION_PARAMETERS_CATEGORIES_POSITIONS(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/categories/positions`;
    },
    ORGANIZATION_PARAMETERS_CATEGORIES_ACCREDITATIONS(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/categories/accreditations`;
    },
    ORGANIZATION_PARAMETERS_TAGS(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/tags`;
    },
    ORGANIZATION_PARAMETERS_TRANSLATIONS(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/translations`;
    },
    ORGANIZATION_PARAMETERS_INVOICES(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/invoices`;
    },
    ORGANIZATION_PARAMETERS_ACTIVITY(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/activity`;
    },
    ORGANIZATION_PARAMETERS_SKIDATA(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/skidata`;
    },
    ORGANIZATION_PARAMETERS_ORGANIZATION_SWITCH(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/organization-switch`;
    },
    ORGANIZATION_PARAMETERS_DELETE_ACCOUNT(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION_PARAMETERS(organizationId)}/delete-account`;
    },

    // custom fields
    CUSTOM_FIELDS(id: OrganizationIdPathParam) {
        return `${ORGANIZATION(id)}/custom-fields`;
    },
    CUSTOM_FIELDS_SEGMENT(organizationId: OrganizationIdPathParam, segmentId: SegmentIdPathParam) {
        return `${ORGANIZATION(organizationId)}/custom-fields/${segmentId}`;
    },
    CUSTOM_FIELD_USAGE(
        organizationId: OrganizationIdPathParam,
        segmentId: SegmentIdPathParam,
        customFieldId: CustomFieldIdPathParam
    ) {
        return `${ORGANIZATION(organizationId)}/custom-fields/${segmentId}/usage/${customFieldId}`;
    },
    CREATE_CUSTOM_FIELD(organizationId: OrganizationIdPathParam, segmentId: SegmentIdPathParam) {
        return `${ORGANIZATION(organizationId)}/custom-fields/${segmentId}/create`;
    },
    EDIT_CUSTOM_FIELD(
        organizationId: OrganizationIdPathParam,
        segmentId: SegmentIdPathParam,
        customFieldId: CustomFieldIdPathParam
    ) {
        return `${ORGANIZATION(organizationId)}/custom-fields/${segmentId}/edit/${customFieldId}`;
    },

    // community
    COMMUNITY_USERS_SEGMENT(
        organizationId: OrganizationIdPathParam,
        segmentId: SegmentIdPathParam
    ) {
        return `${ORGANIZATION(organizationId)}/community/users/${segmentId}`;
    },
    COMMUNITY_USERS_USER(
        organizationId: OrganizationIdPathParam,
        segmentId: SegmentIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${ORGANIZATION(organizationId)}/community/users/${segmentId}/user/${userInfoId}`;
    },
    COMMUNITY_USERS_USER_INFORMATIONS(
        organizationId: OrganizationIdPathParam,
        segmentId: SegmentIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${ORGANIZATION(organizationId)}/community/users/${segmentId}/user/${userInfoId}/${
            PathComponents.SECTIONS.INFORMATION
        }`;
    },
    COMMUNITY_USERS_DUPLICATES(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION(organizationId)}/community/users-duplicates`;
    },
    COMMUNITY_EDIT_USER(organizationId: OrganizationIdPathParam, userInfoId: UserInfoIdPathParam) {
        return `${ORGANIZATION(organizationId)}/community/user/${userInfoId}/edit`;
    },
    COMMUNITY_EDIT_USER_FORM(
        organizationId: OrganizationIdPathParam,
        userInfoId: UserInfoIdPathParam,
        formId: FormIdPathParam | 'all' | 'private'
    ) {
        return `${ORGANIZATION(organizationId)}/community/user/${userInfoId}/edit/form/${formId}`;
    },
    COMMUNITY_CAMPAIGNS(id: OrganizationIdPathParam) {
        return `${ORGANIZATION(id)}/community/campaigns`;
    },
    COMMUNITY_CREATE_CAMPAIGN(id: OrganizationIdPathParam, options?: PathParam) {
        let path = `${ORGANIZATION(id)}/community/create-campaign`;

        if (options) {
            path = `${path}/${options}`;
        }

        return path;
    },
    COMMUNITY_CAMPAIGNS_SEGMENT(
        organizationId: OrganizationIdPathParam,
        segmentId: SegmentIdPathParam
    ) {
        return `${ORGANIZATION(organizationId)}/community/campaigns/${segmentId}`;
    },
    COMMUNITY_CAMPAIGN(organizationId: OrganizationIdPathParam, campaignId: CampaignIdPathParam) {
        return `${ORGANIZATION(organizationId)}/community/campaign/${campaignId}`;
    },
    COMMUNITY_CAMPAIGN_DASHBOARD(
        organizationId: OrganizationIdPathParam,
        campaignId: CampaignIdPathParam
    ) {
        return `${ORGANIZATION(organizationId)}/community/campaign/${campaignId}/dashboard`;
    },
    COMMUNITY_CAMPAIGN_DASHBOARD_USER(
        organizationId: OrganizationIdPathParam,
        campaignId: CampaignIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${ORGANIZATION(
            organizationId
        )}/community/campaign/${campaignId}/dashboard/user/${userInfoId}`;
    },
    COMMUNITY_CAMPAIGN_DASHBOARD_USER_INFORMATIONS(
        organizationId: OrganizationIdPathParam,
        campaignId: CampaignIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${ORGANIZATION(
            organizationId
        )}/community/campaign/${campaignId}/dashboard/user/${userInfoId}/${
            PathComponents.SECTIONS.INFORMATION
        }`;
    },
    COMMUNITY_CAMPAIGN_PREVIEW(
        organizationId: OrganizationIdPathParam,
        campaignId: CampaignIdPathParam
    ) {
        return `${ORGANIZATION(organizationId)}/community/campaign/${campaignId}/preview`;
    },
    COMMUNITY_EDIT_CAMPAIGN(
        organizationId: OrganizationIdPathParam,
        campaignId: CampaignIdPathParam
    ) {
        return `${ORGANIZATION(organizationId)}/community/campaign/${campaignId}/edit`;
    },
    COMMUNITY_FORMS(id: OrganizationIdPathParam) {
        return `${ORGANIZATION(id)}/community/forms`;
    },
    COMMUNITY_FORM(organizationId: OrganizationIdPathParam, formId: FormIdPathParam) {
        return `${ORGANIZATION(organizationId)}/community/form/${formId}`;
    },
    COMMUNITY_VOLUNTEERS_IMPORT(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION(organizationId)}/community/import`;
    },
    COMMUNITY_FILES_IMPORT(organizationId: OrganizationIdPathParam) {
        return `${ORGANIZATION(organizationId)}/community/documents-import`;
    },
    COMMUNITY_USER_PROFILE(
        organizationId: OrganizationIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${ORGANIZATION(organizationId)}/user/${userInfoId}/profile`;
    },
    COMMUNITY_DELEGATIONS(id: OrganizationIdPathParam) {
        return `${ORGANIZATION(id)}/community/delegations`;
    },
    COMMUNITY_DELEGATIONS_SEGMENT(
        organizationId: OrganizationIdPathParam,
        segmentId: SegmentIdPathParam
    ) {
        return `${ORGANIZATION(organizationId)}/community/delegations/${segmentId}`;
    },
    COMMUNITY_DELEGATION(
        organizationId: OrganizationIdPathParam,
        delegationId: DelegationIdPathParam
    ) {
        return `${ORGANIZATION(organizationId)}/community/delegation/${delegationId}`;
    },
    COMMUNITY_CREATE_DELEGATION(id: OrganizationIdPathParam) {
        return `${ORGANIZATION(id)}/community/create-delegation`;
    },
    COMMUNITY_EDIT_DELEGATION(
        organizationId: OrganizationIdPathParam,
        delegationId: DelegationIdPathParam
    ) {
        return `${ORGANIZATION(organizationId)}/community/delegation/${delegationId}/edit`;
    },
    COMMUNITY_EDIT_USER_DELEGATION(
        organizationId: OrganizationIdPathParam,
        delegationId: DelegationIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${ORGANIZATION(
            organizationId
        )}/community/delegation/${delegationId}user/${userInfoId}/edit`;
    },
    COMMUNITY_EDIT_USER_DELEGATION_FORM(
        organizationId: OrganizationIdPathParam,
        delegationId: DelegationIdPathParam,
        userInfoId: UserInfoIdPathParam,
        formId: FormIdPathParam | 'all' | 'private'
    ) {
        return `${ORGANIZATION(
            organizationId
        )}/community/delegation/${delegationId}/user/${userInfoId}/edit/form/${formId}`;
    },

    // events
    CREATE_EVENT_WITH_ORGANIZATION: '/create-event',
    CREATE_EVENT(id: OrganizationIdPathParam) {
        return `${ORGANIZATION(id)}/create-event`;
    },
    EVENTS(id: OrganizationIdPathParam) {
        return `${ORGANIZATION(id)}/events`;
    },
    EVENTS_SEGMENT(id: OrganizationIdPathParam, segmentId: SegmentIdPathParam) {
        return `${ORGANIZATION(id)}/events/${segmentId}`;
    },
    EVENT,
    EVENT_DELEGATIONS_IMPORT(organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) {
        return `${EVENT(organizationId, eventId)}/delegations-import`;
    },
    EVENT_VOLUNTEERS_IMPORT(organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) {
        return `${EVENT(organizationId, eventId)}/members-import`;
    },
    EVENT_FILES_IMPORT(organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) {
        return `${EVENT(organizationId, eventId)}/documents-import`;
    },
    EDIT_EVENT(organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) {
        return `${EVENT(organizationId, eventId)}/edit`;
    },

    // dashboard
    DASHBOARD,
    DASHBOARD_EVENT_SWITCH(organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) {
        return `${DASHBOARD(organizationId, eventId)}/event-switch`;
    },

    // positions categories
    POSITION_CATEGORY,
    POSITION_MEMBERS,
    POSITION_MEMBERS_USER(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        positionCategoryId: PositionCategoryIdPathParam,
        positionId: PositionIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${POSITION_MEMBERS(
            organizationId,
            eventId,
            positionCategoryId,
            positionId
        )}/${userInfoId}`;
    },
    POSITION_MEMBERS_USER_INFORMATIONS(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        positionCategoryId: PositionCategoryIdPathParam,
        positionId: PositionIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${POSITION_MEMBERS(
            organizationId,
            eventId,
            positionCategoryId,
            positionId
        )}/${userInfoId}/${PathComponents.SECTIONS.INFORMATION}`;
    },

    // positions
    EXPORT_POSITIONS_MAP,
    POSITIONS,
    POSITIONS_SEARCH,
    POSITIONS_SEGMENT,
    CREATE_POSITION(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        options?: PathParam
    ) {
        let path = `${EVENT(organizationId, eventId)}/create-position`;

        if (options) {
            path = `${path}/${options}`;
        }

        return path;
    },
    POSITION_MAP,
    POSITION,
    POSITION_SETTINGS(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        positionId: PositionIdPathParam
    ) {
        return `${POSITION(organizationId, eventId, positionId)}/settings`;
    },
    POSITION_SLOTS(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        positionId: PositionIdPathParam
    ) {
        return `${POSITION(organizationId, eventId, positionId)}/slots`;
    },
    POSITION_VOLUNTEERS(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        positionId: PositionIdPathParam
    ) {
        return `${POSITION(organizationId, eventId, positionId)}/volunteers`;
    },
    EDIT_POSITION(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        positionId: PositionIdPathParam
    ) {
        return `${POSITION(organizationId, eventId, positionId)}/edit`;
    },

    // positions slots
    POSITION_SLOT,
    POSITION_SLOT_ASSIGN_VOLUNTEERS,
    POSITION_SLOT_ASSIGN_VOLUNTEERS_USER,
    POSITION_SLOT_ASSIGN_VOLUNTEERS_USER_INFORMATIONS(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        positionId: PositionIdPathParam,
        positionSlotId: PositionSlotIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${POSITION_SLOT_ASSIGN_VOLUNTEERS_USER(
            organizationId,
            eventId,
            positionId,
            positionSlotId,
            userInfoId
        )}/${PathComponents.SECTIONS.INFORMATION}`;
    },
    POSITION_SLOT_MEMBERS,
    POSITION_SLOT_MEMBERS_USER(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        positionId: PositionIdPathParam,
        positionSlotId: PositionSlotIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${POSITION_SLOT_MEMBERS(
            organizationId,
            eventId,
            positionId,
            positionSlotId
        )}/${userInfoId}`;
    },
    POSITION_SLOT_MEMBERS_USER_INFORMATIONS(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        positionId: PositionIdPathParam,
        positionSlotId: PositionSlotIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${POSITION_SLOT_MEMBERS(
            organizationId,
            eventId,
            positionId,
            positionSlotId
        )}/${userInfoId}/${PathComponents.SECTIONS.INFORMATION}`;
    },
    POSITION_SLOT_MEMBERS_CHECK_OPTIONS(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        positionId: PositionIdPathParam,
        positionSlotId: PositionSlotIdPathParam
    ) {
        return `${POSITION_SLOT_MEMBERS(
            organizationId,
            eventId,
            positionId,
            positionSlotId
        )}/check-options`;
    },
    POSITION_SLOT_CHECK_MEMBERS_CHECK_IN(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        positionId: PositionIdPathParam,
        positionSlotId: PositionSlotIdPathParam
    ) {
        return `${POSITION_SLOT_CHECK_MEMBERS(
            organizationId,
            eventId,
            positionId,
            positionSlotId
        )}/check-in`;
    },
    POSITION_SLOT_CHECK_MEMBERS_CHECK_OUT(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        positionId: PositionIdPathParam,
        positionSlotId: PositionSlotIdPathParam
    ) {
        return `${POSITION_SLOT_CHECK_MEMBERS(
            organizationId,
            eventId,
            positionId,
            positionSlotId
        )}/check-out`;
    },
    POSITION_SLOT_CHECK_MEMBERS_RESET(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        positionId: PositionIdPathParam,
        positionSlotId: PositionSlotIdPathParam
    ) {
        return `${POSITION_SLOT_CHECK_MEMBERS(
            organizationId,
            eventId,
            positionId,
            positionSlotId
        )}/reset`;
    },

    // volunteers
    VOLUNTEERS,
    VOLUNTEERS_SEARCH,
    VOLUNTEERS_SEARCH_USER,
    VOLUNTEERS_SEARCH_USER_INFORMATIONS(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${VOLUNTEERS_SEARCH_USER(organizationId, eventId, userInfoId)}/${
            PathComponents.SECTIONS.INFORMATION
        }`;
    },
    VOLUNTEERS_SEGMENT(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        segmentId: SegmentIdPathParam
    ) {
        return `${VOLUNTEERS(organizationId, eventId)}/${segmentId}`;
    },
    VOLUNTEERS_LIST_USER,
    VOLUNTEERS_LIST_USER_INFORMATIONS(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        segmentId: SegmentIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${VOLUNTEERS_LIST_USER(organizationId, eventId, segmentId, userInfoId)}/${
            PathComponents.SECTIONS.INFORMATION
        }`;
    },
    VOLUNTEERS_LIST_USER_PANEL,
    VOLUNTEERS_LIST_USER_PANEL_ACCREDITATIONS(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        segmentId: SegmentIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${VOLUNTEERS_LIST_USER_PANEL(
            organizationId,
            eventId,
            segmentId,
            userInfoId
        )}/accreditations`;
    },
    VOLUNTEERS_LIST_USER_PANEL_AVAILABILITIES(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        segmentId: SegmentIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${VOLUNTEERS_LIST_USER_PANEL(
            organizationId,
            eventId,
            segmentId,
            userInfoId
        )}/availabilities`;
    },
    // users
    EDIT_USER(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${EVENT(organizationId, eventId)}/user/${userInfoId}/edit`;
    },
    EDIT_USER_FORM(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        userInfoId: UserInfoIdPathParam,
        formId: FormIdPathParam | 'all' | 'private'
    ) {
        return `${EVENT(organizationId, eventId)}/user/${userInfoId}/edit/form/${formId}`;
    },
    EDIT_USER_MISSIONS(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${EVENT(organizationId, eventId)}/user/${userInfoId}/edit-missions`;
    },
    EDIT_USER_MISSIONS_FORM(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        userInfoId: UserInfoIdPathParam,
        formId: FormIdPathParam
    ) {
        return `${EVENT(organizationId, eventId)}/user/${userInfoId}/edit-missions/form/${formId}`;
    },
    EDIT_USER_SLOTS(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${EVENT(organizationId, eventId)}/user/${userInfoId}/edit-slots`;
    },
    EDIT_USER_SLOTS_FORM(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        userInfoId: UserInfoIdPathParam,
        formId: FormIdPathParam
    ) {
        return `${EVENT(organizationId, eventId)}/user/${userInfoId}/edit-slots/form/${formId}`;
    },
    EDIT_USER_ACCREDITATIONS(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${EVENT(organizationId, eventId)}/user/${userInfoId}/edit-accreditations`;
    },
    EDIT_USER_ACCREDITATIONS_FORM(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        userInfoId: UserInfoIdPathParam,
        formId: FormIdPathParam
    ) {
        return `${EVENT(
            organizationId,
            eventId
        )}/user/${userInfoId}/edit-accreditations/form/${formId}`;
    },
    EVENT_USER_PROFILE(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${EVENT(organizationId, eventId)}/user/${userInfoId}/profile`;
    },

    // assignments
    ASSIGNMENTS(organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) {
        return `${EVENT(organizationId, eventId)}/assignments`;
    },
    ASSIGNMENT(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${EVENT(organizationId, eventId)}/assignment/${userInfoId}`;
    },
    EDIT_ASSIGNMENT(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${EVENT(organizationId, eventId)}/edit-assignment/${userInfoId}`;
    },
    TEAM_ASSIGNMENT(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        teamCode: TeamCodePathParam
    ) {
        return `${EVENT(organizationId, eventId)}/team-assignment/${teamCode}`;
    },
    PRE_ASSIGNMENT(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${EVENT(organizationId, eventId)}/pre-assignment/${userInfoId}`;
    },

    // accredits
    ACCREDITATIONS_ASSIGNMENTS(organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) {
        return `${EVENT(organizationId, eventId)}/accreditations-assignments`;
    },
    ACCREDIT(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${EVENT(organizationId, eventId)}/accredit/${userInfoId}`;
    },
    EDIT_ACCREDIT(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${EVENT(organizationId, eventId)}/edit-accredit/${userInfoId}`;
    },

    // emails
    EVENT_EMAILS,
    EVENT_EMAILS_PARAMETERS(organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) {
        return `${EVENT_EMAILS(organizationId, eventId)}/parameters`;
    },

    // custom documents
    EDIT_CUSTOM_DOCUMENT(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        customDocumentId: CustomDocumentIdPathParam
    ) {
        return `${EVENT(organizationId, eventId)}/custom-document/${customDocumentId}/edit`;
    },

    // forms
    FORMS,
    FORM,
    FORM_PARAMETERS,

    // integration
    INTEGRATION,
    INTEGRATION_LINKS(organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) {
        return `${INTEGRATION(organizationId, eventId)}/links`;
    },
    INTEGRATION_FIELDS(organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) {
        return `${INTEGRATION(organizationId, eventId)}/fields`;
    },
    INTEGRATION_PARAMETERS(organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) {
        return `${INTEGRATION(organizationId, eventId)}/parameters`;
    },
    INTEGRATION_DESIGN(organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) {
        return `${INTEGRATION(organizationId, eventId)}/design`;
    },

    // campaigns
    CAMPAIGNS(organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) {
        return `${EVENT(organizationId, eventId)}/campaigns`;
    },
    CREATE_CAMPAIGN(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        options?: PathParam
    ) {
        let path = `${EVENT(organizationId, eventId)}/create-campaign`;

        if (options) {
            path = `${path}/${options}`;
        }

        return path;
    },
    CAMPAIGN,
    CAMPAIGN_DASHBOARD(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        campaignId: CampaignIdPathParam
    ) {
        return `${CAMPAIGN(organizationId, eventId, campaignId)}/dashboard`;
    },
    CAMPAIGN_DASHBOARD_USER(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        campaignId: CampaignIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${CAMPAIGN(organizationId, eventId, campaignId)}/dashboard/user/${userInfoId}`;
    },
    CAMPAIGN_DASHBOARD_USER_INFORMATIONS(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        campaignId: CampaignIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${CAMPAIGN(organizationId, eventId, campaignId)}/dashboard/user/${userInfoId}/${
            PathComponents.SECTIONS.INFORMATION
        }`;
    },
    CAMPAIGN_PREVIEW(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        campaignId: CampaignIdPathParam
    ) {
        return `${CAMPAIGN(organizationId, eventId, campaignId)}/preview`;
    },
    EDIT_CAMPAIGN(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        camaignId: CampaignIdPathParam
    ) {
        return `${CAMPAIGN(organizationId, eventId, camaignId)}/edit`;
    },

    // documents
    DOCUMENTS,
    DOCUMENTS_USER_PLANNING(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${DOCUMENTS(organizationId, eventId)}/user-planning/${userInfoId}`;
    },
    DOCUMENTS_USER_PLANNING_LIST(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${DOCUMENTS(organizationId, eventId)}/user-planning-list/${userInfoId}`;
    },
    DOCUMENTS_USER_PLANNING_DAYS(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${DOCUMENTS(organizationId, eventId)}/user-planning-days/${userInfoId}`;
    },
    DOCUMENTS_ASSIGNMENTS(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${DOCUMENTS(organizationId, eventId)}/assignments/${userInfoId}`;
    },
    DOCUMENTS_VOLUNTEERS_LISTING(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        segmentId: SegmentIdPathParam
    ) {
        return `${DOCUMENTS(organizationId, eventId)}/volunteers-listing/${segmentId}`;
    },
    DOCUMENTS_BADGE(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        userInfoId: UserInfoIdPathParam,
        badgeId: BadgeTypePathParam
    ) {
        return `${DOCUMENTS(organizationId, eventId)}/user/${userInfoId}/badge/${badgeId}`;
    },
    DOCUMENTS_CERTIFICATE(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${DOCUMENTS(organizationId, eventId)}/user/${userInfoId}/certificate`;
    },
    DOCUMENTS_POSITION_PLANNING(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        positionId: PositionIdPathParam
    ) {
        return `${DOCUMENTS(organizationId, eventId)}/position-planning/${positionId}`;
    },
    DOCUMENTS_POSITIONS_VOLUNTEERS_PLANNING(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        segmentId: SegmentIdPathParam
    ) {
        return `${DOCUMENTS(organizationId, eventId)}/positions-volunteers-planning/${segmentId}`;
    },
    DOCUMENTS_POSITION(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        positionId: PositionIdPathParam
    ) {
        return `${DOCUMENTS(organizationId, eventId)}/position/${positionId}`;
    },
    DOCUMENTS_PLANNING(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        segmentId: SegmentIdPathParam
    ) {
        return `${DOCUMENTS(organizationId, eventId)}/planning/${segmentId}`;
    },
    DOCUMENTS_USER_CUSTOM_DOCUMENT(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        userInfoId: UserInfoIdPathParam,
        customDocumentId: CustomDocumentIdPathParam
    ) {
        return `${DOCUMENTS(
            organizationId,
            eventId
        )}/user/${userInfoId}/custom-document/${customDocumentId}`;
    },

    // api
    API,
    API_API(organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) {
        return `${API(organizationId, eventId)}/api`;
    },
    API_INTEGRATION(organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) {
        return `${API(organizationId, eventId)}/integration`;
    },
    API_WEEZEVENT(organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) {
        return `${API(organizationId, eventId)}/weezevent`;
    },
    API_WEEZEVENT_V2(organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) {
        return `${API(organizationId, eventId)}/weezevent-v2`;
    },
    CREATE_WEEZEVENT_RULE(organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) {
        return `${API(organizationId, eventId)}/weezevent/create-rule`;
    },
    EDIT_WEEZEVENT_RULE(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        ruleId: WeezeventRuleIdPathParam
    ) {
        return `${API(organizationId, eventId)}/weezevent/rule/${ruleId}/edit`;
    },

    // accreditations
    ACCREDITATIONS,
    ACCREDITATIONS_SEGMENT,
    ACCREDITATION_ACCREDIT,
    ACCREDITATION_ACCREDIT_USER,
    ACCREDITATION_ACCREDIT_USER_INFORMATIONS(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        accreditationId: AccreditationIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${ACCREDITATION_ACCREDIT_USER(
            organizationId,
            eventId,
            accreditationId,
            userInfoId
        )}/${PathComponents.SECTIONS.INFORMATION}`;
    },
    CREATE_ACCREDITATION(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        options?: PathParam
    ) {
        let path = `${EVENT(organizationId, eventId)}/create-accreditation`;

        if (options) {
            path = `${path}/${options}`;
        }

        return path;
    },
    EDIT_ACCREDITATION(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        accreditationId: AccreditationIdPathParam
    ) {
        return `${ACCREDITATION(organizationId, eventId, accreditationId)}/edit`;
    },
    ACCREDITATION_MAP(eventId: EventIdPathParam, accreditationId: AccreditationIdPathParam) {
        return `/accreditation/${eventId}-${accreditationId}/map`;
    },

    // delegations
    DELEGATIONS,
    DELEGATIONS_SEGMENT,
    DELEGATION,
    CREATE_DELEGATION(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        options?: PathParam
    ) {
        let path = `${EVENT(organizationId, eventId)}/create-delegation`;

        if (options) {
            path = `${path}/${options}`;
        }

        return path;
    },
    EDIT_DELEGATION(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        delegationId: DelegationIdPathParam
    ) {
        return `${DELEGATION(organizationId, eventId, delegationId)}/edit`;
    },
    EDIT_USER_DELEGATION(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        delegationId: DelegationIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) {
        return `${DELEGATION(organizationId, eventId, delegationId)}/user/${userInfoId}/edit`;
    },
    EDIT_USER_DELEGATION_FORM(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        delegationId: DelegationIdPathParam,
        userInfoId: UserInfoIdPathParam,
        formId: FormIdPathParam | 'all' | 'private'
    ) {
        return `${DELEGATION(
            organizationId,
            eventId,
            delegationId
        )}/user/${userInfoId}/edit/form/${formId}`;
    }
};

const VOLUNTEERS_EVENT = (organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) =>
    `/volunteers/${organizationId}-${eventId}`;
const ASSIGNMENTS = (organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) =>
    `${VOLUNTEERS_EVENT(organizationId, eventId)}/assignments`;

export const VolunteersPaths = {
    AUTH(organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) {
        return `${VOLUNTEERS_EVENT(organizationId, eventId)}/auth`;
    },
    MAGIC_LINK(
        code: PathParam,
        source: PathParam,
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam
    ) {
        return `/magic-link/${code}/${source}/${organizationId}/${eventId}`;
    },
    REGISTER(organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) {
        return `${VOLUNTEERS_EVENT(organizationId, eventId)}/register`;
    },
    ASSIGNMENTS,
    ASSIGNMENTS_REGISTRATION(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        volunteerRegistrationId: VolunteerRegistrationIdPathParam
    ) {
        return `${ASSIGNMENTS(organizationId, eventId)}/registration/${volunteerRegistrationId}`;
    },
    EDIT_ASSIGNMENTS_REGISTRATION(
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        volunteerRegistrationId: VolunteerRegistrationIdPathParam
    ) {
        return `${ASSIGNMENTS(
            organizationId,
            eventId
        )}/registration/${volunteerRegistrationId}/edit`;
    }
};

export function getEmbedPath(isEmbed: boolean, path: string): string {
    if (isEmbed && !path.startsWith('/embed')) {
        return `/embed${path}`;
    } else {
        return path;
    }
}
