import { injectable } from 'inversify';
import * as yup from 'yup';
import {
    ALL_VOLUNTEERSIMPORTMODE,
    CustomFieldId,
    VolunteersImportInput,
    VolunteersImportMode
} from '../generated/types';
import { TranslationService } from '../services/translationService';
import { CsvInputService } from './csvInput';
import { DocumentInputService } from './documentInput';
import { InputService } from './inputService';

export const MEMBERS_IMPORT_COLUMNS_DEFAULT = {
    /* Each field can be required or optional, depending on the import mode selected */
    Email: 'email',
    Id: 'id'
};

@injectable()
export class VolunteersImportInputService extends InputService {
    constructor(
        private documentInputService: DocumentInputService,
        private csvInputService: CsvInputService,
        translationService: TranslationService
    ) {
        super(translationService);
    }

    default(): { input: VolunteersImportInput } {
        return {
            input: {
                csv: this.documentInputService.documentInputDefault(),
                mappings: [],
                presets: [],
                mode: VolunteersImportMode.CreateUpdateFromEmail
            }
        };
    }

    schema() {
        return yup.object().shape({
            input: yup.object().shape({
                csv: this.csvInputService.schema({
                    defaultColumns: MEMBERS_IMPORT_COLUMNS_DEFAULT
                }),
                mappings: yup.array().of(yup.string().nullable()),
                presets: yup.array().of(
                    yup.object().shape({
                        customFieldId: yup.number().positive(this.t('le_choix_du_typ_07079')),
                        value: yup.mixed().when('customFieldId', {
                            is: (customFieldId: CustomFieldId) => customFieldId > -1,
                            then: (schema: yup.MixedSchema) =>
                                schema.required(this.t('la_valeur_du_ch_65599'))
                        })
                    })
                ),
                mode: yup.string().oneOf(ALL_VOLUNTEERSIMPORTMODE, this.t('le_mode_d_impor_75706'))
            })
        });
    }
}
